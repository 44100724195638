import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { Session } from "src/app/interfaces/session";
import { DataService } from "src/app/services/data.service";

@Component({
  selector: "session-name",
  template: `
    <div>
      <h4>{{ 'session_description_title' | translate }}</h4>
      <hr />
      <p class="text-left">{{ 'session_description1' | translate }}</p>
      <mat-form-field appearance="fill">
        <mat-label>{{ "session-description" | translate }}</mat-label>
        <textarea
          matInput
          #description
          [formControl]="sessionDescriptionValidator"
          cdkTextareaAutosize
          cdkAutosizeMinRows="6"
          cdkAutosizeMaxRows="8"
          id="descriptionInput"
        ></textarea>
        <mat-error *ngIf="sessionDescriptionValidator.invalid">
          {{ getErrorMessagePass() }}
        </mat-error>
      </mat-form-field>
      <mat-dialog-actions align="end">
        <button mat-button class="rounded-cards" mat-dialog-close>
          {{ "cancel" | translate }}
        </button>
        <button
          mat-flat-button
          id="send-button"
          class="rounded-cards"
          [mat-dialog-close]="true"
          [disabled]="session.description == ''"
          (click)="updateSessionDescription('')"
        >
          {{ "delete_description" | translate }}
        </button>
        <button
          mat-flat-button
          id="send-button"
          class="rounded-cards"
          [mat-dialog-close]="true"
          color="primary"
          [disabled]="sessionDescriptionValidator.invalid"
          (click)="updateSessionDescription(description.value)"
        >
          {{ "accept" | translate }}
        </button>
      </mat-dialog-actions>
    </div>
  `,
  styleUrls: ["./dialog.css"],
})
export class sessionDescriptionDialog implements OnInit {
  sessionDescriptionValidator = new FormControl(this.session.description, [Validators.required]);

  constructor(
    public translate: TranslateService,
    public dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public session
  ) {}

  ngOnInit() {}

  updateSessionDescription(sessionDescription: string) {
    let session: Session = {
      sid: this.session.sid,
      description: sessionDescription,
    };
    this.dataService.updateSession(session);
  }

  getErrorMessagePass() {
    let trans1 = "";
    if (this.sessionDescriptionValidator.hasError("required")) {
      this.translate
        .get("obligatorio-entrar-valor") //ENTER VALUE REQUIRED
        .subscribe((translation) => {
          trans1 = translation;
        });
      return trans1;
    }
  }
}
