import { DataStorage } from "src/app/services/data.storage";
import { Component, ViewChild, NgModule, OnInit } from "@angular/core";
import { AuthService } from "./auth/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { MatSidenav } from "@angular/material/sidenav";
import { User } from "./interfaces/user";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  @ViewChild("sidenav") sidenav: MatSidenav;
  title = "aibizfy-dev";
  toolbar_displayed: Boolean;
  userInfo: User = {};
  userDataInfo: User = {};

  constructor(
    public authService: AuthService,
    private translate: TranslateService,
    private _data: DataStorage,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.toolbar_displayed = true;
    this._data = _data;
  }

  ngOnInit() {
    let subscription = this._data
      .getEmittedValue()
      .subscribe((item) => (this.toolbar_displayed = item));

    this.authService.
      getUserInfo().
      subscribe((userInfo) => {
      this.userInfo = userInfo
    });

    this.authService.getUserDataInfo().subscribe((userDataInfo) => {
      this.userDataInfo = userDataInfo
      if(this.userDataInfo) if(this.userDataInfo.language) if (!(this.router.url.includes('discover') || this.router.url.includes('prioritize'))) {
        this.translate.setDefaultLang(this.userDataInfo.language);
        console.log("default lang", this.userDataInfo.language);
      }
    });
  }

  // set app language
  useLanguage(language: string): void {
    this.translate.use(language);
  }
}
