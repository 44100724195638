import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DataService } from "src/app/services/data.service";

@Component({
  selector: "confirmation",
  template: `
    <div>
      <h1 mat-dialog-title>
        <strong>{{ "create-session" | translate }}</strong>
      </h1>

      <div mat-dialog-content class="mat-typography">
        <div class="alert alert-info d-flex align-items-center" role="alert">
          <mat-icon class="bi flex-shrink-0 me-2" width="24" height="24"
            >info</mat-icon
          >
          <div>
            <strong>{{ "alert-delete-session" | translate }}</strong>
          </div>
        </div>
        <h3>
          <p [innerHTML]="'use_session_alert' | translate : { cliente: this.data }"></p>
          {{ "seguro_continuar" | translate }}
        </h3>
      </div>
      <mat-dialog-actions align="end">
        <button
          mat-button
          class="rounded-cards"
          mat-dialog-close
          cdkFocusInitial
        >
          {{ "Cancel·lar" | translate }}
        </button>
        <button
          mat-flat-button
          class="rounded-cards"
          [mat-dialog-close]="true"
          color="warn"
        >
          {{ "Acceptar" | translate }}
        </button>
      </mat-dialog-actions>
    </div>
  `,
  styleUrls: ["./dialog.css"],
})
export class AddSessionDialog {
  constructor(
    private dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}
}
