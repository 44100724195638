import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { DataService } from "src/app/services/data.service";
import { User } from "src/app/interfaces/user";
import { AuthService } from "src/app/auth/auth.service";
import { Customer } from "src/app/interfaces/customer";

@Component({
  selector: "dynamic-comp",
  template: `<div>
    <h4>{{ "customer-logo3" | translate }}</h4>
    <hr />
    <div class="mb-3">
      <label for="formFile" class="form-label">
        {{ "customer-logo4" | translate }}
      </label>
      <input
        class="form-control"
        type="file"
        id="formFile"
        #file
        [formControl]="logoValidator"
      />
      <mat-error *ngIf="logoValidator.invalid">
        {{ getErrorMessagePass() }}
      </mat-error>
    </div>
    <mat-dialog-actions>
      <div class="photo_buttons">
        <button mat-button class="rounded-cards" id="add-photo" mat-dialog-close *ngIf="this.data.logoURL != null" (click)="deleteLogo(this.data.cid)">
          {{ "delete-photo" | translate }}
        </button>
        <button
          mat-flat-button
          class="rounded-cards"
          id="add-photo"
          (click)="saveFile(this.data.cid)"
          color="primary"
          [disabled]="logoValidator.invalid"
        >
          <span id="button-content" *ngIf="this.data.logoURL == null">{{
            "add-logo" | translate
          }}</span>
          <span id="button-content" *ngIf="this.data.logoURL != null">{{
            "update-logo" | translate
          }}</span>
        </button>
      </div>
    </mat-dialog-actions>
  </div>`,
  styleUrls: ["../client-home.component.css"],
})
export class LogoComponent implements OnInit {
  logoValidator = new FormControl("", [Validators.required]);
  file;

  constructor(
    public dataService: DataService,
    public authService: AuthService,
    public translate: TranslateService,
    public dialogRef: MatDialogRef<LogoComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
    let element = document.getElementById("formFile") as HTMLInputElement;
    let component = this;
    element.onchange = function (event) {
      component.file = element.files[0];
    };
  }

  deleteLogo(customerId) {
    let customer: Customer = {
      cid: customerId,
      logoURL: null,
    };
    this.dataService.updateCustomer(customer);
  }

  saveFile(customerId) {
    this.dataService.uploadCustomerLogo(this.file, customerId,this.data.logoURL);
    this.dialogRef.close();    
  }

  getErrorMessagePass() {
    let trans1 = "";
    if (this.logoValidator.hasError("required")) {
      this.translate
        .get("obligatorio-entrar-valor") //ENTER VALUE REQUIRED
        .subscribe((translation) => {
          trans1 = translation;
        });
      return trans1;
    }
  }
}
