import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared.module';

import { UserRoutingModule } from './user-routing.module';

import { AccountPageComponent } from './account-page/account-page.component';
import { PhotoComponent } from './account-page/dialogs/photo.component';
import { NameComponent } from './account-page/dialogs/name.component';
import { PasswordComponent } from './account-page/dialogs/password.component';
import { TipusClientComponent } from './account-page/dialogs/tipus-client.component';
import { PagamentComponent } from './account-page/dialogs/pay.component';
import { LanguageComponent } from './account-page/dialogs/language.component';


@NgModule({
  declarations: [
    AccountPageComponent,
    PhotoComponent,
    NameComponent,
    PasswordComponent,
    TipusClientComponent,
    PagamentComponent,
    LanguageComponent
  ],
  imports: [
    CommonModule,
    UserRoutingModule,
    SharedModule
  ]
})
export class UserModule { }
