import { Component, Inject, OnInit } from "@angular/core";
import {
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { DataSourceCombo } from "src/app/interfaces/canvas_combos/dataSourceCombo";
import { DataTypeCombo } from "src/app/interfaces/canvas_combos/dataTypeCombo";
import { EconomicValuesCombo } from "src/app/interfaces/canvas_combos/economicValuesCombo";
import { OrganizationValuesCombo } from "src/app/interfaces/canvas_combos/OrganizationValues";
import { OutcomeDataCombo } from "src/app/interfaces/canvas_combos/outcomeDataCombo";
import { ProcessedDataCombo } from "src/app/interfaces/canvas_combos/processedDataCombo";
import dataSource from "src/assets/standardData/canvas_combos/dataSource.json";
import dataType from "src/assets/standardData/canvas_combos/dataType.json";
import processedData from "src/assets/standardData/canvas_combos/processedData.json";
import outcomeData from "src/assets/standardData/canvas_combos/outcomeData.json";
import economicValues from "src/assets/standardData/canvas_combos/economicValues.json";
import organizationalValues from "src/assets/standardData/canvas_combos/organizationValues.json";
import { TranslateService } from "@ngx-translate/core";
import { DataService } from "src/app/services/data.service";
import { Session } from "src/app/interfaces/session";

@Component({
  selector: "confirmation",
  templateUrl: "./wizard-canvas.dialog.html",
  styleUrls: ["./wizard-canvas.dialog.css"],
})
export class WizardCanvas implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<WizardCanvas>,
    public translate: TranslateService,
    private dataService: DataService,
  ) {}

  dataSourceCombo: DataSourceCombo[] = dataSource;
  dataTypeCombo: DataTypeCombo[] = dataType;
  processedDataCombo: ProcessedDataCombo[] = processedData;
  outcomeDataCombo: OutcomeDataCombo[] = outcomeData;
  economicValuesCombo: EconomicValuesCombo[] = economicValues;
  organizationValuesCombo: OrganizationValuesCombo[] = organizationalValues;

  combosGran;

  dialogWidth: number = 570;
  elementRef: HTMLElement = this.data.element;
  posit = Object.assign({},this.data.posit ? this.data.posit : {});
  selected;
  sessionLang: string;
  session: Session = {};

  ngOnInit(): void {
    this.dataService.getSession(this.data.canvas.sessionId).subscribe((data) => {
      let sessionLang = data.lang;
      this.translate.setDefaultLang(sessionLang);
    })
    
    var id = this.elementRef.id;
    const matDialogConfig = new MatDialogConfig();
    switch (id) {
      case "regulation":
        this.combosGran = null;
        //position
        matDialogConfig.position = {
          left: this.elementRef.offsetLeft + 130 + "px",
          top:
            this.elementRef.offsetHeight +
            this.elementRef.offsetTop +
            45 +
            "px",
        };
        this.dialogRef.updatePosition(matDialogConfig.position);
        break;

      case "dataSource":
        this.combosGran = this.dataSourceCombo;
        //position
        matDialogConfig.position = {
          left:
            this.elementRef.offsetWidth +
            this.elementRef.offsetLeft +
            85 +
            "px",
        };
        this.dialogRef.updatePosition(matDialogConfig.position);
        break;

      case "dataType":
        this.combosGran = this.dataTypeCombo;
        //position
        matDialogConfig.position = {
          left:
            this.elementRef.offsetWidth +
            this.elementRef.offsetLeft +
            85 +
            "px",
        };
        this.dialogRef.updatePosition(matDialogConfig.position);
        break;

      case "processed":
        this.combosGran = this.processedDataCombo;
        //position
        matDialogConfig.position = {
          left:
            this.elementRef.offsetWidth +
            this.elementRef.offsetLeft +
            85 +
            "px",
        };
        this.dialogRef.updatePosition(matDialogConfig.position);
        break;

      case "values":
        this.combosGran = null;
        //position
        matDialogConfig.position = {
          left:
            this.elementRef.offsetWidth +
            this.elementRef.offsetLeft +
            85 +
            "px",
        };
        this.dialogRef.updatePosition(matDialogConfig.position);
        break;

      case "outcome":
        this.combosGran = this.outcomeDataCombo;
        //position
        matDialogConfig.position = {
          left: this.elementRef.offsetLeft - this.dialogWidth + "px",
        };
        this.dialogRef.updatePosition(matDialogConfig.position);
        break;

      case "economics":
        this.combosGran = this.economicValuesCombo;
        //position
        matDialogConfig.position = {
          left: this.elementRef.offsetLeft - this.dialogWidth + 40 + "px",
        };
        this.dialogRef.updatePosition(matDialogConfig.position);
        break;

      case "customer":
        this.combosGran = null;
        //position
        matDialogConfig.position = {
          left: this.elementRef.offsetLeft - this.dialogWidth + 40 + "px",
        };
        this.dialogRef.updatePosition(matDialogConfig.position);
        break;

      case "organization":
        this.combosGran = this.organizationValuesCombo;
        //position
        matDialogConfig.position = {
          left: this.elementRef.offsetLeft - this.dialogWidth + 40 + "px",
        };
        this.dialogRef.updatePosition(matDialogConfig.position);
        break;

      case "ethics":
        this.combosGran = null;
        //position
        matDialogConfig.position = {
          left: this.elementRef.offsetLeft + 130 + "px",
          top: this.elementRef.offsetHeight - 15 + "px",
        };
        this.dialogRef.updatePosition(matDialogConfig.position);
        break;
      default:
        break;
    }
  }

  stepToShow(posit) {
    if (posit.categoria) {
      if (posit.tipus || posit.text) {
        if (!posit.tipus) {
          return 1;
        } else return 2;
      } else return 0;
    } else return 0;
  }

  onClickCategoria(categoria) {
    this.posit.categoria = categoria;
  }
}
