import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { DataService } from "src/app/services/data.service";
import { User } from "src/app/interfaces/user";
import { AuthService } from "src/app/auth/auth.service";

@Component({
  selector: "dynamic-comp",
  template: `<div>
    <h4>{{ "change-foto" | translate }}</h4>
    <hr />
    <p class="text-left">
      
    </p>
    <div class="mb-3">
      <label for="formFile" class="form-label">
        {{ "change-foto-text" | translate }}
      </label>
      <input
        class="form-control"
        type="file"
        id="formFile"
        #file
        [formControl]="photoValidator"
      />
      <mat-error *ngIf="photoValidator.invalid">
        {{ getErrorMessagePass() }}
      </mat-error>
    </div>
    <mat-dialog-actions>
      <div class="photo_buttons">
        <button mat-button class="rounded-cards" id="add-photo" mat-dialog-close *ngIf="userInfo.photoURL != null" (click)="deletePhoto()">
          {{ "delete-photo" | translate }}
        </button>
        <button
          mat-flat-button
          class="rounded-cards"
          id="add-photo"
          (click)="saveFile()"
          color="primary"
          [disabled]="photoValidator.invalid"
        >
          <span id="button-content" *ngIf="userInfo.photoURL == null">{{
            "add-foto" | translate
          }}</span>
          <span id="button-content" *ngIf="userInfo.photoURL != null">{{
            "update-foto" | translate
          }}</span>
        </button>
      </div>
    </mat-dialog-actions>
  </div>`,
  styleUrls: ["../account-page.component.css"],
})
export class PhotoComponent implements OnInit {
  photoValidator = new FormControl("", [Validators.required]);
  file;

  constructor(
    public dataService: DataService,
    public authService: AuthService,
    public translate: TranslateService,
    public dialogRef: MatDialogRef<PhotoComponent>,
    @Inject(MAT_DIALOG_DATA) public userInfo: User
  ) {}

  ngOnInit(): void {
    let element = document.getElementById("formFile") as HTMLInputElement;
    let component = this;
    element.onchange = function (event) {
      component.file = element.files[0];
    };
  }

  deletePhoto() {
    this.dataService.deleteProfilePhoto(this.userInfo.photoURL);
  }

  saveFile() {
    this.dataService.uploadProfilePhoto(this.file, this.userInfo.photoURL);
    this.dialogRef.close();
  }

  getErrorMessagePass() {
    let trans1 = "";
    if (this.photoValidator.hasError("required")) {
      this.translate
        .get("obligatorio-entrar-valor") //ENTER VALUE REQUIRED
        .subscribe((translation) => {
          trans1 = translation;
        });
      return trans1;
    }
  }
}
