import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { ActivationComponent } from './activation/activation.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { PlanPendentComponent } from './plan-pendent/plan-pendent.component';
import { SelectPlanComponent } from './select-plan/select-plan.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';

const routes: Routes = [
  { path: 'sign-in', component: SignInComponent },
  { path: 'register-user', component: SignUpComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { 
    path: 'activation', 
    component: ActivationComponent, 
    canActivate: [AuthGuard]
  },
  { 
    path: 'verify-email-address', 
    component: VerifyEmailComponent,
    canActivate: [AuthGuard]
  },
  { 
    path: 'plan-pendent', 
    component: PlanPendentComponent,
    canActivate: [AuthGuard]
  },
  { 
    path: 'select-plan', 
    component: SelectPlanComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccesRoutingModule { }
