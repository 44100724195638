import { Component, Inject, OnInit } from "@angular/core";
import {
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { Session } from "src/app/interfaces/session";
import { DataService } from "src/app/services/data.service";

@Component({
  selector: "session-lang",
  template: `
    <div>
      <h4>{{ 'change_language' | translate }}</h4>
      <hr />
      <mat-form-field appearance="fill">
        <mat-label class="form-label">{{ "idioma" | translate }}</mat-label>
        <mat-select #idiom>
          <mat-option
            *ngFor="let idioma of this.idiomas"
            [value]="idioma"
          >
            {{ idioma | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-dialog-actions align="end">
        <button mat-button class="rounded-cards" mat-dialog-close>
          {{ "cancel" | translate }}
        </button>
        <button
          mat-flat-button
          id="send-button"
          class="rounded-cards"
          [mat-dialog-close]="true"
          color="primary"
          (click)="updateSessionLang(idiom.value)"
        >
          {{ "accept" | translate }}
        </button>
      </mat-dialog-actions>
    </div>
  `,
  styleUrls: ["./dialog.css"],
})
export class sessionLangDialog implements OnInit {

    idiomas = ['es','ca','en'];

  constructor(
    public translate: TranslateService,
    public dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public session
  ) {}

  ngOnInit() {}

  updateSessionLang(sessionLang: string) {
    let session: Session = {
      sid: this.session.sid,
      lang: sessionLang,
    };
    this.dataService.updateSession(session);
  }

  
}
