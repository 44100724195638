import { DataService } from "../../services/data.service";
import { Component, OnInit } from "@angular/core";
import { Session } from "../../interfaces/session";
import { DataStorage } from "src/app/services/data.storage";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { Sort } from "@angular/material/sort";
import { FormControl, Validators } from "@angular/forms";
import { Participant } from "src/app/interfaces/participant";
import { MatSnackBar, MatSnackBarHorizontalPosition } from "@angular/material/snack-bar";
import { Customer } from "src/app/interfaces/customer";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialog2 } from "./confirmation-dialog/confirmation.dialog";
import { User } from "src/app/interfaces/user";
import { AccountControllerService } from "src/app/services/accountController.service";
import platformServiceslist from "src/assets/standardData/platformServices.json";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { sessionNameDialog } from "./info-dialogs/session-name.dialog";
import { discoverTimeDialog } from "./info-dialogs/discover-time.dialog";
import { sessionParticipantsDialog } from "./info-dialogs/participants.dialog";
import { sessionDescriptionDialog } from "./info-dialogs/session-description.dialog";
import { sessionLangDialog } from "./info-dialogs/session-lang.dialog";
import { FrameworkTitolsColumnesDialog } from "./framework-dialogs/framework-titols-columnes.dialog";
import { FrameworkTitolsFilesDialog } from "./framework-dialogs/framework-titols-files.dialog";
import { DeleteFrameworkDialog } from "./confirmation-dialog/delete-framework-confirmation.dialog";

export interface Verb {
  name: string;
  aparicions: number;
  percentatge: number;
}
export interface Color {
  name: string;
  aparicions: number;
  percentatge: number;
}
@Component({
  selector: "app-session-view",
  templateUrl: "./session-view.component.html",
  styleUrls: ["./session-view.component.css"],
})
export class SessionViewComponent implements OnInit {
  participantNameValidator = new FormControl("", [Validators.required]);
  participantMailValidator = new FormControl("", [Validators.required, Validators.email])

  emptyValue = "";
  emptyValue2 = "";

  hostname = "";
  port = "";

  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  durationInSeconds = 4;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  session: Session = {};
  participant: Participant = {};
  cartesTrobades: number = 0;
  platformServiceslist = platformServiceslist;
  hasParticipants=false;

  verb: Verb[] = [];
  color: Color[] = [];
  verbSortedData: Verb[] = [];
  colorSortedData: Color[] = [];
  customer: Customer = {};
  verbsReals;
  userDataInfo: User;

  active = 0;
  

  constructor(
    private _data: DataStorage,
    private dataService: DataService,
    public translate: TranslateService,
    public router: Router,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    public sessionAuth: AccountControllerService,
  ) {}

  ngOnInit(): void {
    let sessionId = this._data.getSid();
    this.session.sid = sessionId;
    this.dataService.getSession(sessionId).subscribe((data) => {
      this.session = data;
      this.session.sid = this._data.getSid();
      this.cartesTrobades = this.dataService.getCardsNumber(sessionId);
      this.dataService.getCustomer(this.session.cid).subscribe(customer => {
        this.customer = customer as Customer
      })
      this.dataService.getVerbs().subscribe((data) => {
        this.verbsReals = data;
        if(this.session.analitics) {
          this.getVerbs();
          this.getColors();
          this.verbSortData({active: "aparicions", direction: "desc"})
          this.colorSortData({active: "aparicions", direction: "desc"})
        }
      })
      this.dataService.getParticipants(this.session.sid).subscribe(participants => {
        this.session.participants = participants as Participant[];
        if(this.session.participants.length > 0) this.hasParticipants = true;
        else this.hasParticipants = false;
      });
    });
    this.hostname = window.location.hostname;
    this.port = window.location.port;
  }

  getNumeroVegades(indexColumnes: number, indexFiles: number) {
    let position = indexFiles * this.session.framework.numeroColumnes + indexColumnes;

    let framePosition = this.session.framework.quadratsClicats.find((item) => item.posicio === position);

    if (framePosition) {
      return framePosition.vegades;
    } else {
      return '0';
    }
  }

  eliminaFramework() {
    const dialogRef = this.dialog.open(DeleteFrameworkDialog, {
      data: this.session,
      minHeight: '200px',
      maxWidth: '500px',
      panelClass: 'rounded-cards'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result){
        let session: Session = {
          sid: this.session.sid,
          framework: null,
        };
        console.log(session)
        this.dataService.updateSession(session);
      }
    });
  }

  //DIALOGS
  openConfirmationDialog() {
    const dialogRef = this.dialog.open(ConfirmationDialog2, {
      data: this.session,
      minHeight: '200px',
      maxWidth: '500px',
      panelClass: 'rounded-cards'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result){
        this.endSession();
      }
    });
  }

  openSessionNameDialog() {
    const dialogRef = this.dialog.open(sessionNameDialog, {
      data: this.session,
      minHeight: '200px',
      width: '500px',
      panelClass: 'rounded-cards'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result){}
    });
  }

  openDiscoverTimeDialog() {
    const dialogRef = this.dialog.open(discoverTimeDialog, {
      data: this.session,
      minHeight: '200px',
      width: '500px',
      panelClass: 'rounded-cards'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result){}
    });
  }

  openSessionDescriptionDialog() {
    const dialogRef = this.dialog.open(sessionDescriptionDialog, {
      data: this.session,
      minHeight: '200px',
      width: '500px',
      panelClass: 'rounded-cards'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result){}
    });
  }

  openSessionLangDialog() {
    const dialogRef = this.dialog.open(sessionLangDialog, {
      data: this.session,
      minHeight: '200px',
      width: '500px',
      panelClass: 'rounded-cards'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result){}
    });
  }

  openSessionParticipantsDialog() {
    const dialogRef = this.dialog.open(sessionParticipantsDialog, {
      data: this.session,
      minHeight: '200px',
      width: '500px',
      panelClass: 'rounded-cards'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result){}
    });
  }

  openFrameworkColumnesDialog() {
    const dialogRef = this.dialog.open(FrameworkTitolsColumnesDialog, {
      data: this.session,
      minHeight: '200px',
      width: '750px',
      panelClass: 'rounded-cards'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result){}
    });
  }

  openFrameworkFilesDialog() {
    const dialogRef = this.dialog.open(FrameworkTitolsFilesDialog, {
      data: this.session,
      minHeight: '200px',
      width: '750px',
      panelClass: 'rounded-cards'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result){}
    });
  }

  getVerbs() {
    let suma: number = 0;
    Object.entries<number>(this.session.analitics.verbs).forEach(([key, value]) => suma += value);
    this.verb = [];
    Object.entries<number>(this.session.analitics.verbs).forEach(([key, value]) => {
      if(value==0) return;
      let percent = (value*100)/(suma*100);
      this.verb.push({
        name: key, 
        aparicions: value, 
        percentatge: percent 
      });
    });
  }

  getColors() {
    let suma: number = 0;
    Object.entries<number>(this.session.analitics.colors).forEach(([key, value]) => suma += value);
    this.color = [];
    Object.entries<number>(this.session.analitics.colors).forEach(([key, value]) => {
      if(value==0) return;
      let percent = (value*100)/(suma*100);
      let name = "";
      switch (key) {
        case "#ffd166":
            name = "intuicion";
            break;
        case "#06d6b0":
            name = "complejidad";
            break;
        case "#26547c": 
            name = "entender";
            break;

        default: 
            console.log("No color type on new card")
      }
      this.color.push({
        name: name, 
        aparicions: value, 
        percentatge: percent 
      });
    });
  }

  addParticipant(name: string, email: string): void {
    let trans1 = "";
    if (this.participantNameValidator.hasError('required') || this.participantMailValidator.hasError('required')) {
      this.translate
        .get("obligatorio-entrar-valor") //ENTER VALUE REQUIRED
        .subscribe((translation) => {
          trans1 = translation;
        });
        this._snackBar.open(trans1, "", {
          duration: this.durationInSeconds * 1000,
          panelClass: ['error-snackbar']
        });
      return;
    } else if (this.participantMailValidator.hasError('email')) {
      this.translate
        .get("email-invalido") //EMAIL NOT VALID
        .subscribe((translation) => {
          this._snackBar.open(translation, "", {
            duration: this.durationInSeconds * 1000,
            panelClass: ['error-snackbar']
          });
        });
      return;
    }
    let p: Participant = {
      name: name,
      mail: email
    }
    this.dataService.setNewParticipant(p, this.session.sid);
    this.participantMailValidator.reset();
    this.participantNameValidator.reset();
    this.emptyValue = "";
    this.emptyValue2 = "";
  }

  endSession() {
    let session: Session = {
      sid: this.session.sid,
      estat: 2
    }
    //this.newUrl();
    this.dataService.updateSession(session);
  }

  remove(part: Participant): void {
    this.dataService.deleteParticipant(part.pid, this.session.sid);
  }

  copyToClipboard() {
    let trans = "";
    var link = document.getElementById("session-link").innerText;
    navigator.clipboard
      .writeText(link)
      .then((doc) => {
        this.translate
        .get("copied-link")
        .subscribe((translation) => {
          trans = translation;
        });
        this._snackBar.open(trans, "", {
          horizontalPosition: this.horizontalPosition,
          duration: this.durationInSeconds * 1000,

        });
      })
      .catch((e) => console.error(e));
  }

  createSessionLink() {
    if (this.session.estat < 2) {
      if (this.port != "") 
        return "http://" + this.hostname + ":" + this.port + "/discover/" + this.session.url;
      else 
        return "https://" + this.hostname + "/discover/" + this.session.url;
    } else if (this.session.estat == 2) {
      if (this.port != "") 
        return "http://" + this.hostname + ":" + this.port + "/prioritize/" + this.session.url;
      else 
        return "https://" + this.hostname + "/prioritize/" + this.session.url;
    }
    
  }

  newUrl() {
    this.dataService.setNewSessionUrl(this.session);
  }

  goSession() {
    let trans = "";
    if (!this.session.valida) {
      this.translate
        .get("session-not-paid")
        .subscribe((translation) => {
          trans = translation;
        });
        this._snackBar.open(trans, "", {
          horizontalPosition: this.horizontalPosition,
          duration: this.durationInSeconds * 1000,
          panelClass: ['error-snackbar']
        });
    } else {
      this.router.navigate(["/discover/" + this.session.url]);
    }
  }

  goPrioritize() {
    let trans = "";
    if (!this.session.valida) {
      this.translate
        .get("session-not-paid")
        .subscribe((translation) => {
          trans = translation;
        });
        this._snackBar.open(trans, "", {
          horizontalPosition: this.horizontalPosition,
          duration: this.durationInSeconds * 1000,
          panelClass: ['error-snackbar']
        });
    } else {
      window.open("/prioritize/" + this.session.url);
      // this.router.navigate(["/prioritize/" + this.session.url]);
    }
  }

  verbSortData(sort: Sort) {
    const data = this.verb.slice();
    if (!sort.active || sort.direction === "") {
      this.verbSortedData = data;
      return;
    }
    this.verbSortedData = data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        case "name":
          return compare(a.name, b.name, isAsc);
        case "aparicions":
          return compare(a.aparicions, b.aparicions, isAsc);
        case "percentatge":
          return compare(a.percentatge, b.percentatge, isAsc);
        default:
          return 0;
      }
    });
  }
  colorSortData(sort: Sort) {
    const data = this.color.slice();
    if (!sort.active || sort.direction === "") {
      this.colorSortedData = data;
      return;
    }

    this.colorSortedData = data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        case "name":
          return compare(a.name, b.name, isAsc);
        case "aparicions":
          return compare(a.aparicions, b.aparicions, isAsc);
        case "percentatge":
          return compare(a.percentatge, b.percentatge, isAsc);
        default:
          return 0;
      }
    });
  }

  showTime(seconds: number): string {
    let disTimeEnt = Math.trunc(seconds / 60);
    let disTimePart = Math.trunc((seconds / 60 - disTimeEnt) * 60);
    return disTimeEnt+ ":" + disTimePart.toLocaleString('en-US', {
                                minimumIntegerDigits: 2,
                                useGrouping: false
                              }); 
  }

  activarServei(event: MatSlideToggleChange, serveiId: number) {
    if (!this.serveiDesabilitat(serveiId)) {
      this.dataService.modifyService(this.session.sid, serveiId, event.checked);
    }
  }

  serveiActivat(serveiId) {
    if (!this.session.serveisActivats) return false;
    return this.session.serveisActivats[serveiId];
  }

  serveiDesabilitat(serveiId) {
    return !this.sessionAuth.serveiDisponible(serveiId,this.session.tipusSessio);
  }

  count(i: number) {
    return new Array(i);
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
