import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { DataService } from "src/app/services/data.service";
import { DataStorage } from "src/app/services/data.storage";
import { Canvas } from "../prioritize/canvas-dialog/canvas.dialog";
import { WizardCanvas } from "./wizard-canvas-dialog/wizard-canvas.dialog";
import { NgxCaptureService } from "ngx-capture";
import { tap } from "rxjs/operators";
import { Location } from '@angular/common';

@Component({
  selector: "app-canvas",
  templateUrl: "./canvas.component.html",
  styleUrls: ["./canvas.component.css"],
})
export class CanvasComponent implements OnInit, OnDestroy {
  constructor(
    private _data: DataStorage,
    private dataService: DataService,
    private router: Router,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private captureService: NgxCaptureService,
    private location: Location,
  ) {}

  @ViewChild('screen', { static: false }) screen: any;

  img = ""
  canvasId: string;
  canvas: Canvas;
  cardName: string;

  ngOnInit(): void {
    document.body.style.backgroundColor = "#333333";
    this._data.setToolbarDisplay(false);
    this.canvasId = this.route.snapshot.url[1].path;
    this.dataService.getCanvas(this.canvasId).subscribe((data) => {
      this.canvas = data as Canvas;
    })
  }

  ngOnDestroy() {
    document.body.style.backgroundColor = "";
    this._data.setToolbarDisplay(true);
  }

  goPrioritize() {
    console.log("anar a prioritize");
    // this.router.navigate(["/priorizite/" + 1]);
  }

  createPosit(element: HTMLElement) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '43vh';
    dialogConfig.height = '67vh';
    dialogConfig.panelClass = "rounded-cards-dark";
    dialogConfig.data = {
      element: element,
      posit: null,
      canvas: this.canvas
    };
    dialogConfig.backdropClass = 'backdropBackground';

    Array.from(
      window.document.getElementsByClassName("enfosquir")
    ).forEach(function (element: HTMLElement) {
      element.style.opacity = "0.3";
    });

    element.style.opacity = "1";

    const dialogRef = this.dialog.open(WizardCanvas, dialogConfig);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {     //map --> result:posit
        switch (result.action) {
          case 0:
            //elimina
            break;
          case 1:
            //no fa res
            break;
          case 2:
            //actualitza
            break;
          case 3:
            //guarda
            this.dataService.addPosit(result.posit, this.canvasId, element.id);
            break;
          default:
            break;
        }
      }
      Array.from(
        window.document.getElementsByClassName("enfosquir")
      ).forEach(function (element: HTMLElement) {
        element.style.opacity = "1";
      });
    });
  }

  modifyPosit(posit, element) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '57vh';
    dialogConfig.maxWidth = 'fit-content';
    dialogConfig.height = '67vh';
    dialogConfig.panelClass = "rounded-cards-dark";
    dialogConfig.data = {
      element: element,
      posit: posit,
      canvas: this.canvas
    };
    dialogConfig.backdropClass = 'backdropBackground';

    Array.from(
      window.document.getElementsByClassName("enfosquir")
    ).forEach(function (element: HTMLElement) {
      element.style.opacity = "0.3";
    });

    element.style.opacity = "1";

    const dialogRef = this.dialog.open(WizardCanvas, dialogConfig);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {     //map --> result:posit
        switch (result.action) {
          case 0:
            //elimina
            this.dataService.deletePosit(result.posit, this.canvasId, element.id);
            break;
          case 1:
            //no fa res
            break;
          case 2:
            //actualitza
            this.dataService.updatePosit(posit, result.posit, this.canvasId, element.id);
            break;
          case 3:
            //guarda
            this.dataService.addPosit(result.posit, this.canvasId, element.id);
            break;
          default:
            break;
        }
      }
      Array.from(
        window.document.getElementsByClassName("enfosquir")
      ).forEach(function (element: HTMLElement) {
        element.style.opacity = "1";
      });
    });
  }

  saveCanvas() {
    this.captureService.getImage(this.screen.nativeElement, true).pipe(
      tap(img => {
        this.img = img
        this.dataService.saveCanvasImage(img, this.canvasId); 
        this.location.back();
      })
    ).subscribe();
  }
}
