// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfigDev = {
  apiKey: "AIzaSyD4hFerOh_d85ARdrWbAR8PVQAXehzNTc4",
  authDomain: "aibizfy-dev.firebaseapp.com",
  projectId: "aibizfy-dev",
  storageBucket: "aibizfy-dev.appspot.com",
  messagingSenderId: "1034056243960",
  appId: "1:1034056243960:web:b331ee7d9f40df97d7f90d",
  measurementId: "G-S9FBVLL985"
};

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBe_gaIwZ8sBScQcZSq6Uweyc3j_mNPj1A",
  authDomain: "aibizfy.firebaseapp.com",
  databaseURL: "https://aibizfy.firebaseio.com",
  projectId: "aibizfy",
  storageBucket: "aibizfy.appspot.com",
  messagingSenderId: "225327972677",
  appId: "1:225327972677:web:77a16ada9243b306cb7e2c",
  measurementId: "G-MQSKHVRTSQ"
};

export const environment = {
  production: false,
  firebaseConfigDev,
  firebaseConfig
};

