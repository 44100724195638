import { Component, OnInit } from "@angular/core";

@Component({
  selector: "dynamic-comp",
  template: `
    <div>
      <div class="title_dialog">
        <h4>Omple el formulari amb al teva informació</h4>
        <p>En breu ens posarem en contacte amb tu</p>
      </div>

      <mat-dialog-actions align="end">
        <button mat-button class="rounded-cards" mat-dialog-close>
          {{ "cancel" | translate }}
        </button>
        <button
          mat-flat-button
          class="rounded-cards"
          [mat-dialog-close]="true"
          color="primary"
        >
          Continuar
        </button>
      </mat-dialog-actions>
    </div>
  `,
  styleUrls: ["../account-page.component.css"],
})
export class PagamentComponent implements OnInit {
  ngOnInit() {}

  constructor() {}
}
