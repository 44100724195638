import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "src/app/auth/auth.service";
import { Customer } from "src/app/interfaces/customer";
import { DataService } from "src/app/services/data.service";

/*
export interface DialogData {
  animal: 'panda' | 'unicorn' | 'lion';
}
*/

@Component({
  selector: "app-sign-up",
  templateUrl: "./sign-up.component.html",
  styleUrls: ["./sign-up.component.css", "../acces-module.css"],
})
export class SignUpComponent implements OnInit, OnDestroy {
  emailValidator = new FormControl("", [Validators.required, Validators.email]);
  userValidator = new FormControl("", [Validators.required]);
  passwordValidator = new FormControl("", [Validators.required]);
  confirmPasswordValidator = new FormControl("", [Validators.required]);

  hide = true;
  
  constructor(
    public authService: AuthService,
    public translate: TranslateService,
    public dialog: MatDialog,
    private dataService: DataService
  ) {}

  signUp(email: any, password: any, confirmpass: any, username: string) {
    this.authService.SignUp(email, password, confirmpass, username).then((userId)=> {
      let customer: Customer = {
        name: "Trial Customer",
      }
      this.dataService.setTrialCustomer(customer, userId);
    })
  }

  enter(e) {
    if (e.key == "Enter") {
      let focus = document.activeElement;
      if (!document.activeElement) {
        document.getElementById("loginButton").focus()
      } else if (focus.id != "mailInput" && focus.id != "userInput" && focus.id != "passInput" && focus.id != "confirmPassInput") {
        document.getElementById("mailInput").focus();
      } else if (focus.id == "mailInput") {
        document.getElementById("userInput").focus();
      } else if (focus.id == "userInput") {
        document.getElementById("passInput").focus();
      } else if (focus.id == "passInput") {
        document.getElementById("confirmPassInput").focus();
      } else if (focus.id == "confirmPassInput") {
        document.getElementById("create-button").focus();
      }
    }
  }

  ngOnInit(): void {
    document.addEventListener("keypress", this.enter);
  }

  ngOnDestroy(): void {
    document.removeEventListener("keypress", this.enter);
  }

  /**
   * display error message if there is any
   * @returns translation string
   */
  getErrorMessageEmail() {
    let trans = "";
    if (this.emailValidator.hasError("required")) {
      this.translate
        .get("obligatorio-entrar-valor") //ENTER VALUE REQUIRED
        .subscribe((translation) => {
          trans = translation;
        });
      return trans;
    } else if (this.emailValidator.hasError("email")) {
      this.translate
        .get("email-invalido") //EMAIL NOT VALID
        .subscribe((translation) => {
          trans = translation;
        });
      return trans;
    } else {
      return "";
    }
  }

  getErrorMessagePass() {
    let trans1 = "";
    if (this.passwordValidator.hasError("required")) {
      this.translate
        .get("obligatorio-entrar-valor") //ENTER VALUE REQUIRED
        .subscribe((translation) => {
          trans1 = translation;
        });
      return trans1;
    }
  }

  getErrorMessagePass2() {
    let trans = "";
    if (this.confirmPasswordValidator.hasError("required")) {
      this.translate
        .get("password-not-match") //ENTER VALUE REQUIRED
        .subscribe((translation) => {
          trans = translation;
        });
      return trans;
    } 
  }
}
