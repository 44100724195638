import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { AngularFireFunctions } from "@angular/fire/functions";
import { MatCheckboxChange } from "@angular/material/checkbox";

@Component({
  selector: "dynamic-comp",
  template: `
    <div>
      <div class="title_dialog">
        <h3>{{ "plan-title" | translate }}</h3>
      </div>
      <mat-dialog-content>
      <div class="row">
        <div class="col-4">
          <mat-card class="mat-elevation-z4 rounded-cards choose_type">
            <mat-card-title> Free</mat-card-title>
            <mat-card-content>
              <p class="price">0€</p>
              <hr />
              <div class="caracteristiques">
                <div class="icon_and_text">
                  <mat-icon class="check_icons">check</mat-icon>
                  {{ "free1" | translate }}
                </div>
                <div class="icon_and_text">
                  <mat-icon class="check_icons">check</mat-icon>
                  {{ "free2" | translate }}
                </div>
                <div class="icon_and_text">
                  <mat-icon class="check_icons">check</mat-icon>
                  {{ "free3" | translate }}
                </div>
                <div class="icon_and_text">
                  <mat-icon class="check_icons">check</mat-icon>
                  {{ "free4" | translate }}
                </div>
                <div class="icon_and_text">
                  <mat-icon class="check_icons">check</mat-icon>
                  {{ "free5" | translate }}
                </div>
              </div>
            </mat-card-content>
            <div class="subcard">
              <mat-card-actions class="card_buttons">
                <button
                  mat-flat-button
                  #free
                  class="rounded-cards request_button"
                  color="primary"
                  value="1"
                  [disabled]="userInformation.userDataInfo.accountType == 1"
                  (click)="openDialogPagament('1')"
                >
                  <span *ngIf="userInformation.userDataInfo.accountType != 1"
                    >{{ "request" | translate }}</span
                  >
                  <span *ngIf="userInformation.userDataInfo.accountType == 1"
                    >{{ "actual" | translate }}</span
                  >
                </button>
              </mat-card-actions>
            </div>
          </mat-card>
        </div>

        <div class="col-4">
          <mat-card class="mat-elevation-z4 rounded-cards choose_type">
            <mat-card-title> Basic</mat-card-title>
            <mat-card-content>
              <p class="price">{{ cost_basic }}€/{{ "month" | translate }}</p>
              <hr />
              <div class="caracteristiques">
              <div class="icon_and_text">
                  <mat-icon class="check_icons">check</mat-icon>
                  {{ "basic1" | translate }}
                </div>
                <div class="icon_and_text">
                  <mat-icon class="check_icons">check</mat-icon>
                  {{ "basic2" | translate }}
                </div>
                <div class="icon_and_text">
                  <mat-icon class="check_icons">check</mat-icon>
                  {{ "basic3" | translate }}
                </div>
                <div class="icon_and_text">
                  <mat-icon class="check_icons">check</mat-icon>
                  {{ "basic4" | translate }}
                </div>
                <div class="icon_and_text">
                  <mat-icon class="check_icons">check</mat-icon>
                  {{ "basic5" | translate }}
                </div>
                <div class="icon_and_text">
                  <mat-icon class="check_icons">check</mat-icon>
                  {{ "basic6" | translate }}
                </div>
                <div class="icon_and_text">
                  <mat-icon class="check_icons">check</mat-icon>
                  {{ "basic7" | translate }}
                </div>
              </div>
              <div class="caracteristiques">
                <mat-checkbox class="example-margin" 
                  [disabled]="this.userInformation.userDataInfo.serveisDisponibles.includes(1)" 
                  [(ngModel)]="tarjetes" 
                  (change)="checked($event)" 
                >
                  {{ 'activar_tarjetes' | translate }}
                </mat-checkbox>
              </div>
            </mat-card-content>
            <div class="subcard">
              <mat-card-actions class="card_buttons">
              <button
                  mat-flat-button
                  #free
                  class="rounded-cards request_button"
                  color="primary"
                  value="1"
                  [disabled]="userInformation.userDataInfo.accountType == 2"
                  (click)="openDialogPagament('2')"
                >
                  <span *ngIf="userInformation.userDataInfo.accountType != 2"
                    >{{ "request" | translate }}</span
                  >
                  <span *ngIf="userInformation.userDataInfo.accountType == 2"
                    >{{ "actual" | translate }}</span
                  >
                </button>
                <button
                  mat-flat-button
                  #free
                  class="rounded-cards request_button"
                  color="primary"
                  value="1"
                  *ngIf="userInformation.userDataInfo.accountType == 2 && !this.userInformation.userDataInfo.serveisDisponibles.includes(1)"
                  (click)="openDialogPagament('2')"
                >
                  <span>
                    {{ "request_services" | translate }}
                  </span>
                </button>
              </mat-card-actions>
            </div>
          </mat-card>
        </div>

        <div class="col-4">
          <mat-card class="mat-elevation-z4 rounded-cards choose_type">
            <mat-card-title> Advanced</mat-card-title>
            <mat-card-content>
              <p class="price">149€/mes</p>
              <hr />
              <div class="caracteristiques">
              <div class="icon_and_text">
                  <mat-icon class="check_icons">check</mat-icon>
                  {{ "advanced1" | translate }}
                </div>
                <div class="icon_and_text">
                  <mat-icon class="check_icons">check</mat-icon>
                  {{ "advanced2" | translate }}
                </div>
                <div class="icon_and_text">
                  <mat-icon class="check_icons">check</mat-icon>
                  {{ "advanced3" | translate }}
                </div>
                <div class="icon_and_text">
                  <mat-icon class="check_icons">check</mat-icon>
                  {{ "advanced4" | translate }}
                </div>
                <div class="icon_and_text">
                  <mat-icon class="check_icons">check</mat-icon>
                  {{ "advanced5" | translate }}
                </div>
                <div class="icon_and_text">
                  <mat-icon class="check_icons">check</mat-icon>
                  {{ "advanced6" | translate }}
                </div>
                <div class="icon_and_text">
                  <mat-icon class="check_icons">check</mat-icon>
                  {{ "advanced7" | translate }}
                </div>
                <div class="icon_and_text">
                  <mat-icon class="check_icons">check</mat-icon>
                  {{ "advanced8" | translate }}
                </div>
              </div>
            </mat-card-content>
            <div class="subcard">
              <mat-card-actions class="card_buttons">
              <button
                  mat-flat-button
                  #free
                  class="rounded-cards request_button"
                  color="primary"
                  value="1"
                  [disabled]="userInformation.userDataInfo.accountType == 3"
                  (click)="openDialogPagament('3')"
                >
                  <span *ngIf="userInformation.userDataInfo.accountType != 3"
                    >{{ "request" | translate }}</span
                  >
                  <span *ngIf="userInformation.userDataInfo.accountType == 3"
                    >{{ "actual" | translate }}</span
                  >
                </button>
              </mat-card-actions>
            </div>
          </mat-card>
        </div>

        <div id="main-title">
          <h2 id="info">{{ "faqs" | translate }}</h2>
        </div>
        <div id="faqs">
          <mat-accordion multi>
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ "faq1-title" | translate }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p>
                {{ "faq1.1" | translate }}
              </p>
              <ul>
                <li>{{ "faq1.2" | translate }}</li>
                <li>
                  {{ "faq1.3" | translate }}
                </li>
                <li>
                  {{ "faq1.4" | translate }}
                </li>
              </ul>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ "faq2-title" | translate }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p>
                {{ "faq2.1" | translate }}<br />
                {{ "faq2.2" | translate }}
              </p>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ "faq3-title" | translate }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p>
                {{ "faq3.1" | translate }}<br />
                {{ "faq3.2" | translate }}<br />
                {{ "faq3.3" | translate }}<br />
                {{ "faq3.4" | translate }}<br />
                {{ "faq3.5" | translate }}
              </p>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
      </mat-dialog-content>
    </div>
  `,
  styleUrls: ["../account-page.component.css"],
})
export class TipusClientComponent implements OnInit {
  durationInSeconds = 4;
  tarjetes: boolean = this.userInformation.userDataInfo.serveisDisponibles.includes(1);
  cost_basic = (this.tarjetes)? 35 : 30;

  constructor(
    public dialog: MatDialog,
    private translate: TranslateService,
    private _snackBar: MatSnackBar,
    private functions: AngularFireFunctions,
    @Inject(MAT_DIALOG_DATA) public userInformation: any
  ) {}

  ngOnInit() {}
  
  checked($event: MatCheckboxChange){
    this.cost_basic = ($event.checked)? 35 : 30;
  }

  openDialogPagament(futureAccountType) {
    let data = {
      userName: this.userInformation.userInfo.displayName,
      email: this.userInformation.userInfo.email,
      accountType: this.userInformation.userDataInfo.accountType.toString(),
      futureAccountType: futureAccountType,
      tarjetes_online: this.tarjetes
    };
    const callable = this.functions.httpsCallable("sendAccountEmail");
    const obs = callable(data);
    obs.subscribe(async (result) => {
      console.log(result);
    });

    this.translate
      .get(
        "Correu enviat. La teva solicitud serà revisada i enviarem un correu amb la confirmació"
      )
      .subscribe((translation) => {
        this._snackBar.open(translation, "", {
          duration: this.durationInSeconds * 1000,
        });
      });

    this.dialog.closeAll();
  }
}
