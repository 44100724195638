import { Component, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "src/app/auth/auth.service";

@Component({
  selector: "dynamic-comp",
  template: `<div>
    <h4>{{ "reset-password" | translate }}</h4>
    <hr />
    <p class="text-left">{{ "solicitud-email" | translate }}</p>
    <mat-form-field appearance="fill" class="material_inputs">
      <mat-label>{{ "user-email" | translate }}</mat-label>
      <input
        matInput
        #email
        placeholder="{{ 'example-mail' | translate }}"
        [formControl]="emailValidator"
        required
        id="mailInput"
      />
      <mat-error *ngIf="emailValidator.invalid">{{
        getErrorMessageEmail()
      }}</mat-error>
    </mat-form-field>
    <mat-dialog-actions align="end">
      <button mat-button class="rounded-cards" mat-dialog-close>
        {{ "cancel" | translate }}
      </button>
      <button
        mat-flat-button
        id="send-button"
        class="rounded-cards"
        [mat-dialog-close]="true"
        color="primary"
        [disabled]="emailValidator.invalid"
        (click)="authService.ForgotPassword(email.value)"
      >
        {{ "accept" | translate }}
      </button>
    </mat-dialog-actions>
  </div>`,
  styleUrls: ["../account-page.component.css"],
})
export class PasswordComponent implements OnInit {

  emailValidator = new FormControl("", [Validators.required, Validators.email]);

  constructor(
    public authService: AuthService,
    public translate: TranslateService
  ) {}

  enter(e) {
    if (e.key == "Enter") {
      let focus = document.activeElement;
      if (
        !document.activeElement ||
        (focus.id != "mailInput" && focus.id != "send-button")
      ) {
        document.getElementById("mailInput").focus();
      } else if (focus.id == "mailInput") {
        document.getElementById("send-button").focus();
      } else if (focus.id == "send-button") {
        document.getElementById("mailInput").focus();
      }
    }
  }

  ngOnInit(): void {
    document.addEventListener("keypress", this.enter);
  }

  ngOnDestroy(): void {
    document.removeEventListener("keypress", this.enter);
  }

  getErrorMessageEmail() {
    let trans = "";
    if (this.emailValidator.hasError("required")) {
      this.translate
        .get("obligatorio-entrar-valor") //ENTER VALUE REQUIRED
        .subscribe((translation) => {
          trans = translation;
        });
      return trans;
    } else if (this.emailValidator.hasError("email")) {
      this.translate
        .get("email-invalido") //EMAIL NOT VALID
        .subscribe((translation) => {
          trans = translation;
        });
      return trans;
    } else {
      return "";
    }
  }
}