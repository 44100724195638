import { DataStorage } from 'src/app/services/data.storage';
import { DataService } from './../../services/data.service';
import { Card } from './../../interfaces/card';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Session } from 'src/app/interfaces/session';
import { OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Customer } from 'src/app/interfaces/customer';
import { MatDialog } from "@angular/material/dialog";
import { Canvas } from './canvas-dialog/canvas.dialog';
import { endSessionDialog } from './confirmation-dialog/confirmation.dialog';

@Component({
  selector: 'app-prioritize',
  templateUrl: './prioritize.component.html',
  styleUrls: ['./prioritize.component.css']
})
export class PrioritizeComponent implements OnInit, OnDestroy {

  session: Session = {};
  sessionUrl: string;
  langAnterior;
  cartaActual;

  fullscreenOn = false;

  customer: Customer = {};

  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    //this.translate.setDefaultLang(this.langAnterior);
    event.preventDefault();
  }

  constructor(
    private dataService: DataService,
    private _data: DataStorage,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.langAnterior = this.translate.getDefaultLang();
    //TODO: GET LANG
    document.body.style.backgroundColor= "#333333";
    this._data.setToolbarDisplay(false);
    this.sessionUrl = this.route.snapshot.url[1].path;
    this.fullscreenOn = (document.fullscreenElement) ? true : false;
    if (this.sessionUrl) {
      this.dataService.getSessionByUrl(this.sessionUrl)
        .subscribe(snapshots => {
          snapshots.forEach(data => {
            this.session = data;
            this.translate.setDefaultLang(this.session.lang);
            this.getCardsState();
            this.dataService.getCustomer(this.session.cid).subscribe(customer => {
              this.customer = customer as Customer
            });
          });
        });
    } else {
      this.router.navigate(['session-not-found']);
    }
  }

  getCardsState() {
    this.dataService.getGroupCards(this.session.sid, 'discovered').subscribe(cards => {
      this.session.discovered = cards as Card[];
      this.session.discovered.sort((a,b) => (a.cardId > b.cardId) ? 1 : -1 );
      this.showCard(this.session.discovered[0], 'discovered');
    });
    this.dataService.getGroupCards(this.session.sid, 'present1').subscribe(cards => {
      this.session.present1 = cards as Card[];
      this.session.present1.sort((a,b) => (a.cardId > b.cardId) ? 1 : -1 );
      if(!this.cartaActual) this.showCard(this.session.present1[0], 'present1');
    });
    this.dataService.getGroupCards(this.session.sid, 'present2').subscribe(cards => {
      this.session.present2 = cards as Card[];
      this.session.present2.sort((a,b) => (a.cardId > b.cardId) ? 1 : -1 );
      if(!this.cartaActual) this.showCard(this.session.present2[0], 'present2');
    });
    this.dataService.getGroupCards(this.session.sid, 'future1').subscribe(cards => {
      this.session.future1 = cards as Card[];
      this.session.future1.sort((a,b) => (a.cardId > b.cardId) ? 1 : -1 );
      if(!this.cartaActual) this.showCard(this.session.future1[0], 'future1');
    });
    this.dataService.getGroupCards(this.session.sid, 'future2').subscribe(cards => {
      this.session.future2 = cards as Card[];
      this.session.future2.sort((a,b) => (a.cardId > b.cardId) ? 1 : -1 );
      if(!this.cartaActual) this.showCard(this.session.future2[0], 'future2');
    });
    this.dataService.getGroupCards(this.session.sid, 'maybe').subscribe(cards => {
      this.session.maybe = cards as Card[];
      this.session.maybe.sort((a,b) => (a.cardId > b.cardId) ? 1 : -1 );
      if(!this.cartaActual) this.showCard(this.session.maybe[0], 'maybe');
    });
  }

  showCard(card: Card, gid: string) {
    let qr = document.getElementById("carta-qr") as HTMLDivElement;
    let cos = document.getElementById("cos-carta-mostra") as HTMLDivElement;
    let image = document.getElementById("img-carta-mostra") as HTMLImageElement;
    let text = document.getElementById("text-carta-mostra") as HTMLParagraphElement;
    let name = document.getElementById("card-name") as HTMLParagraphElement;
    text.textContent = card.text;
    name.textContent = card.name;
    cos.style.backgroundColor = card.color;
    if (card.color == '#06d6b0') {
      image.src = "../../assets/images/aicomplex.svg";
      text.style.color = "white";
    } else if (card.color == '#26547c') {
      image.src = "../../assets/images/aiunderstand.svg";
      text.style.color = "white";
    } else {
      image.src = "../../assets/images/aiintuition.svg";
      text.style.color = '#26547c';
    }
    if (card.canvasURL || card.canvasId) {
      qr.style.backgroundColor = "rgba(0, 255, 0, 0.5)";
    } else qr.style.backgroundColor = "rgba(255, 255, 255, 1)";
    this.cartaActual = {
      sid: this.session.sid,
      card: card,
      gid: gid,
    }
  }

  drop(event: CdkDragDrop<Card[]>) {
    if (event.previousContainer === event.container) {
      return;
    }
    const carta = event.previousContainer.data[event.previousIndex];
    this.dataService.moveCard(this.session.sid, event.previousContainer.id, event.container.id, carta);
    
  }

  openFullscreen() {
    let elem = document.documentElement
    if(elem.requestFullscreen){
      elem.requestFullscreen();
      this.fullscreenOn = true;
    }
  }
  closeFullscreen() {
    if(document.fullscreenElement){
      document.exitFullscreen();
      this.fullscreenOn = false;
    }
  }

  goDiscover() {
    let url = "/discover/" + this.session.url;
    this.router.navigate([url]);
  }

  goSession() {
    this._data.data.sid = this.session.sid;
    this._data.saveData();
    this.translate.setDefaultLang(this.langAnterior);
    let url = "/session/";
    this.router.navigate([url]);
  }

  endSession() {
    let session: Session = {
      sid: this.session.sid,
      estat: 2
    }
    this.dataService.updateSession(session);
  }

  openDialogEndSession() {
    const dialogRef = this.dialog.open(endSessionDialog, {
      data: this.session,
      minHeight: '200px',
      maxWidth: '500px',
      panelClass: 'rounded-cards'
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log(result);
        this.endSession();
      }
    });
  }

  openDialogCanvas() {
    const dialogRef = this.dialog.open(Canvas, {
      data: {
        carta: this.cartaActual,
        session: this.session,
      },
      width: "56vw",
      height: "63vh",
      panelClass: "rounded-cards-dark",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log(result);
      }
    });
  }

  ngOnDestroy() {
    document.body.style.backgroundColor= "";
    this.translate.setDefaultLang(this.langAnterior);
    this._data.setToolbarDisplay(true);
  }

}
