import { Session } from './../interfaces/session';
import { Injectable, OnInit } from "@angular/core";
import { AuthService } from "../auth/auth.service";
import { User } from "../interfaces/user";



@Injectable({
  providedIn: "root",
})
export class AccountControllerService implements OnInit {
  
  constructor(
    public authService: AuthService,
  ) {}

  ngOnInit() {}

  canCreateSession(sType: number): boolean{
    let user = JSON.parse(localStorage.getItem("userData")) as User;
    if (sType == 1) {
      return true;
    } else if (sType == 2) {
      if(user.accountType == 1 && user.sessions >=1) return false;
      else return true;
    }
    return false;

  }

  sessionValid(sType: number): boolean{
    //pagada?
    let user = JSON.parse(localStorage.getItem("userData")) as User;
    if (user.disponibleSessions>0) {
      return true;
    }
    /*
    let user = JSON.parse(localStorage.getItem("userData")) as User;
    if ( sType == 1 ) return true;
    else if ( sType == 2 ){
      if(user.accountType == 3 || user.accountType == 1) return true;
    }*/
    return false;
  }

  pucBorrarCarta(session: Session): boolean {
    if (session.numeroCartesBorrades<=5) return true;
    return false;
  }

  pucCarregarCarta(session: Session): Array<any>{
    if (session.estat>=2) return [false,"session_ended_error"];
    else if (session.tipusSessio==1 && session.numeroCartesCarregades>=5) return [false,"limit_cards_trial"]
     
    return [true,""];
  }

  serveiDisponible(servei: number, sType: number): boolean {
    let user = JSON.parse(localStorage.getItem("userData")) as User;
    if ( user.accountType == 3 || user.accountType == 1 ) return true;
    else if ( user.accountType == 2 ){
      if ( sType == 1 ) return true;
      else if (sType == 2 && user.serveisDisponibles.includes(servei)) {
        return true;
      }
    }
    return false;
  }

}