import { AccountControllerService } from './../../services/accountController.service';
import { DataStorage } from 'src/app/services/data.storage';
import { DataService } from './../../services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, HostListener, OnInit } from '@angular/core';
import { Card } from './../../interfaces/card'

import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';

import { Customer } from 'src/app/interfaces/customer';
import { Session } from 'src/app/interfaces/session';
import { ViewChild } from '@angular/core';
import { CountdownComponent } from 'ngx-countdown';
import { OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { newCardComponent } from './new-card-dialog/newCard.dialog';
import { config } from 'process';



@Component({
  selector: 'app-discover',
  templateUrl: './discover.component.html',
  styleUrls: ['./discover.component.css']
})
export class DiscoverComponent implements OnInit, OnDestroy {


  durationInSeconds = 4;
  sessionUrl: string;
  customer: Customer = {};
  session: Session = {};
  countdown_started = false;
  langAnterior;
  cardsList;
  itsOnline = false;
  
  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;
  
  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    this.saveEstate();
    //this.translate.setDefaultLang(this.langAnterior);
    event.preventDefault();
   
    //event.returnValue = false;
  }
  /*@HostListener("window:onunload", ["$event"]) unloadHandler(event: Event) {
    this.saveEstate();
  }*/


  config = {
    demand: true,
    leftTime: 0,
    format: 'HH:mm:ss'
  }

  fullscreenOn = false;
  wrongtime = false;
  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DataService,
    private _data: DataStorage,
    private accountController: AccountControllerService,
    private _snackBar: MatSnackBar,
    public translate: TranslateService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    //TODO GET LANG
    this.langAnterior = this.translate.getDefaultLang();
    document.body.style.backgroundColor= "#333333";
    this._data.setToolbarDisplay(false);
    this.sessionUrl = this.route.snapshot.url[1].path;
    this.fullscreenOn = (document.fullscreenElement) ? true : false;
    if (this.sessionUrl !== undefined) {
      this.dataService.getSessionByUrl(this.sessionUrl)
        .subscribe(snapshots => {
          
          if (snapshots.length == 0 ) this.router.navigate(['session-not-found']);
          snapshots.forEach(sessiondata => {
            if(this.countdown.config.leftTime != 0) this.wrongtime = true;
            this.session = sessiondata;
            this.itsOnline = this.session.serveisActivats[1]
            
            //set observers to diferent goroup of cards that you can caharge
            this.dataService.getGroupCards(this.session.sid, 'discovered').subscribe(cards => {
              this.session.discovered = cards as Card[];
              this.session.discovered.sort((a,b) => (a.cardId > b.cardId) ? 1 : -1 );
              
            });
            this.dataService.getGroupCards(this.session.sid, 'present1').subscribe(cards => {
              this.session.present1 = cards as Card[];
              this.session.present1.sort((a,b) => (a.cardId > b.cardId) ? 1 : -1 );
            });
            this.dataService.getGroupCards(this.session.sid, 'present2').subscribe(cards => {
              this.session.present2 = cards as Card[];
              this.session.present2.sort((a,b) => (a.cardId > b.cardId) ? 1 : -1 );
            });
            this.dataService.getGroupCards(this.session.sid, 'future1').subscribe(cards => {
              this.session.future1 = cards as Card[];
              this.session.future1.sort((a,b) => (a.cardId > b.cardId) ? 1 : -1 );
            });
            this.dataService.getGroupCards(this.session.sid, 'future2').subscribe(cards => {
              this.session.future2 = cards as Card[];
              this.session.future2.sort((a,b) => (a.cardId > b.cardId) ? 1 : -1 );
            });
            this.dataService.getGroupCards(this.session.sid, 'maybe').subscribe(cards => {
              this.session.maybe = cards as Card[];
              this.session.maybe.sort((a,b) => (a.cardId > b.cardId) ? 1 : -1 );
            });
            this.dataService.getCustomer(sessiondata.cid).subscribe(customerdata => {
              this.customer = customerdata;
              this.customer.cid = sessiondata.cid;

            });
            
            //set counter
            if(!this.wrongtime) {
              if (this.session.counterState) this.countdown.config.leftTime = this.session.counterState;
              else this.countdown.config.leftTime = this.session.discoverTime;
              this.countdown.restart()
              this.countdown.pause()
            }
            
            //Define diferent acctions if session neds to be online or not
            if(this.itsOnline) {
              if (this.session.tipusSessio == 1) {
                this.dataService.getTrialCards().subscribe(clist => {
                  this.cardsList = clist;
                  this.showCardDigital(); // hacerlo solo una vez?
                });
              } else {
                this.dataService.getCards().subscribe(clist => {
                  this.cardsList = clist;
                  this.showCardDigital(); // hacerlo solo una vez?
                });
              }
            } else {
              this.dataService.getChangedCard(this.session.sid, 'discovered').subscribe(document => {
                document.forEach(data => {
                  this.showCard(this.session.discovered[data.payload.newIndex]);
                })
              })
            }
            this.translate.setDefaultLang(this.session.lang);
          });
        });
    } else {
      this.router.navigate(['session-not-found']);
    }

  }
  
  saveEstate(){
    console.log(this.countdown.left)
    let session: Session = {
      sid: this.session.sid,
      counterState: this.countdown.left/1000
    }
    this.dataService.updateSession(session);
  }

  
  startCountdown() {
    this.countdown_started = true;
    this.countdown.begin()
    
  }

  pauseCountdown() {
    this.saveEstate()
    this.countdown_started = false;
    this.countdown.pause()
  }

  substractTime() {
    let milisecondsLeft = this.countdown.left;
    milisecondsLeft = milisecondsLeft-60000;
    let secondsLeft = milisecondsLeft/1000;
    if (secondsLeft > 0) {
      this.countdown.config.leftTime = secondsLeft
      this.countdown.restart()
      if (this.countdown_started) this.countdown.begin();
    } 
    
  }

  addTime() {
    let milisecondsLeft = this.countdown.left;
    milisecondsLeft = milisecondsLeft+300000;
    let secondsLeft = milisecondsLeft/1000;
    if (secondsLeft > 0) {
      this.countdown.config.leftTime = secondsLeft
      this.countdown.restart()
      if (this.countdown_started) this.countdown.begin();
    } 
  }
  
  resetCountdown() {
    this.countdown.config.leftTime = this.session.discoverTime
    this.countdown.restart()
    this.saveEstate();
  }

  showCard(card: Card) {
    let cos = document.getElementById("cos-carta-mostra") as HTMLDivElement;
    let image = document.getElementById("img-carta-mostra") as HTMLImageElement;
    let text = document.getElementById("text-carta-mostra") as HTMLParagraphElement;
    let name = document.getElementById("card-name") as HTMLParagraphElement;

    let cardVerbsString =  this.translate.instant(card.verbs[0]);
    card.verbs.forEach( (verb,index) => {
      if(index!=0) cardVerbsString += ', '+this.translate.instant(verb);
    });

    text.innerHTML = card.text + "<br><br><b>" + cardVerbsString +"</b>";
    name.textContent = card.name;
    cos.style.backgroundColor = card.color;
    if (card.color == '#06d6b0') {
      image.src = "../../assets/images/aicomplex.svg";
      text.style.color = "white";
    } else if (card.color == '#26547c') {
      image.src = "../../assets/images/aiunderstand.svg";
      text.style.color = "white";
    } else {
      image.src = "../../assets/images/aiintuition.svg";
      text.style.color = '#26547c';
    }
  }

  showCardDigital(next?: boolean) {
    let col = document.getElementById("col_carta-online") as HTMLDivElement;
    let cos = document.getElementById("cos-carta-mostra-online") as HTMLDivElement;
    let image = document.getElementById("img-carta-mostra-online") as HTMLImageElement;
    let text = document.getElementById("text-carta-mostra-online") as HTMLParagraphElement;
    let cartaText = document.getElementById("carta-text-online") as HTMLDivElement;
    let name = document.getElementById("card-name-online") as HTMLParagraphElement;

    let index = 0;
    if(col.slot) index = +col.slot;

    if (next) {
      index += 1;
      if(index>=this.cardsList.length) index=0;
    }
    else if (next == false) {
      index -= 1;
      if(index<0) index=this.cardsList.length-1;
    }

    let card = this.cardsList[index]
    col.slot = index.toString();
    let cardVerbsString =  this.translate.instant(card.verbs[0]);
    card.verbs.forEach( (verb,index) => {
      if(index!=0) cardVerbsString += ', '+this.translate.instant(verb);
    });
    switch (this.translate.getDefaultLang()) {
      case 'es':
        text.innerHTML = card.es + "<br><br><b>" + cardVerbsString +"</b>";
        break;
      case 'en':
        text.innerHTML = card.eng + "<br><br><b>" + cardVerbsString +"</b>";
        break;
      case 'ca':
        text.innerHTML = card.ca + "<br><br><b>" + cardVerbsString +"</b>";
        break;
      default:
        text.innerHTML = card.es + "<br><br><b>" + cardVerbsString +"</b>";
        break;
    }
    name.textContent = (index+1).toString() + " de " + (this.cardsList.length).toString()
    cos.style.backgroundColor = card.aiColor;
    if (card.aiColor == '#06d6b0') {
      image.src = "../../assets/images/aicomplex.svg";
      text.style.color = "white";
    } else if (card.aiColor == '#26547c') {
      image.src = "../../assets/images/aiunderstand.svg";
      text.style.color = "white";
    } else {
      image.src = "../../assets/images/aiintuition.svg";
      text.style.color = '#26547c';
    }
  }

  addCartaDialog() {
    let permis = this.accountController.pucCarregarCarta(this.session)
    if(!permis[0]) {
      this._snackBar.open(this.translate.instant(permis[1]), "", {
        duration: this.durationInSeconds * 1000,
        panelClass: ['error-snackbar']
      });
      return;
    }
    let col = document.getElementById("col_carta-online") as HTMLDivElement;
    const dialogRef = this.dialog.open(newCardComponent, {  
      width: "80vw",
      maxHeight: '90vh',
      data: {
        carta: this.cardsList[+col.slot],
        sesionId: this.session.sid,
        session: this.session
      },
      panelClass: "rounded-cards-dark",
    });
    document.body.classList.add("cdk-global-scrollblock");
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log(result);
      }
    });
    document.body.classList.remove("cdk-global-scrollblock");
  }

  goPriorize() {
    let url = "/prioritize/" + this.session.url;
    this.router.navigate([url]);
  }

  openFullscreen() {
    let elem = document.documentElement
    if(elem.requestFullscreen){
      elem.requestFullscreen();
      this.fullscreenOn = true;
    }
  }

  closeFullscreen() {
    if(document.fullscreenElement){
      document.exitFullscreen();
      this.fullscreenOn = false;
    }
  }

  ngOnDestroy() {
    
    //guardar el temps de discover i l'estat
    //S'haura de donar permis a tot quisqui per modificar aquesta variable i les altres
    this.saveEstate();
    this.translate.setDefaultLang(this.langAnterior);
    document.body.style.backgroundColor = ""
    this._data.setToolbarDisplay(true);
  }

  drop(event: CdkDragDrop<Card[]>) {
    if (event.previousContainer === event.container) {
      return;
    } 
    else if (event.container.id == "garbage" && !this.accountController.pucBorrarCarta(this.session)) {
      let trans1 = "";
      this.translate
        .get("max-cards-removed") //ENTER VALUE REQUIRED
        .subscribe((translation) => {
          trans1 = translation;
        });
        this._snackBar.open(trans1, "", {
          duration: this.durationInSeconds * 1000,
        });
      return;
    }
    const carta = event.previousContainer.data[event.previousIndex];
    this.dataService.moveCard(this.session.sid, event.previousContainer.id, event.container.id, carta);
    if (event.container.id == "garbage") {
      this.dataService.restarFramework(this.session.sid, carta.frameworkPosition, this.session.framework);
      this.dataService.actualitzarAnalitics(carta, this.session, this.customer);
    }
  }
}
