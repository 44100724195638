import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DataService } from "src/app/services/data.service";

@Component({
  selector: "confirmation",
  template: `
    <div>
      <h1 mat-dialog-title>
        <strong>{{ "cannot-create-session" | translate }}</strong>
      </h1>

      <div mat-dialog-content class="mat-typography">
        <h3>
          <p [innerHTML]="'no_sessions_message' | translate"></p>
        </h3>
      </div>
      <mat-dialog-actions align="end">
        <button
          mat-button
          class="rounded-cards"
          mat-dialog-close
          cdkFocusInitial
        >
          {{ "cerrar" | translate }}
        </button>
        <button
          mat-flat-button
          class="rounded-cards"
          (click)=" data.window.open('https://shop.aibizfy.com/','_blank')"
          [mat-dialog-close]="true"
          color="primary"
        >
          {{ "comprar" | translate }}
        </button>
      </mat-dialog-actions>
    </div>
  `,
  styleUrls: ["./dialog.css"],
})
export class NoDisponibleSessionsDialog {
  constructor(
    private dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}
}
