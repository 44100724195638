import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Customer } from "src/app/interfaces/customer";


@Component({
    selector: 'confirmation',
    templateUrl: './confirmation.dialog.html',
    styleUrls: ["./confirmation.dialog.scss"],
  })
  export class ConfirmationDialog3 {
    constructor(@Inject(MAT_DIALOG_DATA) public customer: Customer) {}
  }