import { ListKeyManager } from "@angular/cdk/a11y";
import { NONE_TYPE } from "@angular/compiler";
import { Injectable, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Observable } from "rxjs";
import { Customer } from "../interfaces/customer";
import { Card } from "../interfaces/card";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class AnaliticsService implements OnInit {
  card: Card = {};

  constructor(public afs: AngularFirestore, 
    public translate: TranslateService) {}

  ngOnInit() {}

  getGroupedColumnChartData(customerId): Observable<any> {
    
    return new Observable( observer => {
      this.afs.collection('customers').doc(customerId)
      .valueChanges({idFiels: "cid"}).subscribe((customer) => {
        this.afs.collection('analitics').doc('1').valueChanges().subscribe(analitics => {
          let chartData = this.getGroupedColumnChart(customer as Customer, analitics);
          observer.next(chartData);
        })
        
      });
    })
  }

  getGroupedColumnChart(customer, analitics?): any {
    if (!customer.analitics) return -1;
    if (!customer.analitics.verbs) return -1;  
    let title = ""
    let field1 = ""
    let field3 = ""

    this.translate.get("verb-freq-use").subscribe((translation) => {
      title = translation;
    })
    this.translate.get("verb").subscribe((translation) => {
      field1 = translation;
    })
    this.translate.get("all-clients").subscribe((translation) => {
      field3 = translation;
    })
    let verb = []
    let totalValue;
    if (!analitics) totalValue = 0; 
    Object.entries<number>(customer.analitics.verbs).forEach(([key, value]) => {
      if(analitics.verbs[key]) verb.push([key,value, analitics.verbs[key]]);
      else verb.push([key,value, totalValue]);
      
    });

    /**
     * variables necessaries:
     * quantitat de vegades que ha sortit un verb per aquell client
     * quantitat de vegades que ha sortit aquell verb per tots els clients de la plataforma
     * 
     */
    return {
      title: title,
      type: "ColumnChart",
      data: verb,
      columnNames: [field1, customer.name , field3],
    };
  }

  getPieChartData(customerId): Observable<any> {
    return new Observable( observer => {
      this.afs.collection('customers').doc(customerId)
      .valueChanges({idFiels: "color"}).subscribe((customer) => {
        let chartData = this.getPieChart(customer as Customer);
        observer.next(chartData);
      });
    })
  }

  getPieChart(customer): any {
    if (!customer.analitics) return -1;
    if (!customer.analitics.colors) return -1; 
    let title = ""
    let verb1 = ""
    let verb2 = ""
    let verb3 = ""
    this.translate.get("card-volume").subscribe((translation) => {
      title = translation;
    })
    this.translate.get("intuituon").subscribe((translation) => {
      verb1 = translation;
    })
    this.translate.get("complexity").subscribe((translation) => {
      verb2 = translation;
    })
    this.translate.get("understanding").subscribe((translation) => {
      verb3 = translation;
    })
    let suma: number = 0;
    Object.entries<number>(customer.analitics.colors).forEach(([key, value]) => suma += value);
    let color = [];
    let slices = [];
    Object.entries<number>(customer.analitics.colors).forEach(([key, value]) => {
      let name = "";
      switch (key) {
        case "#ffd166":
            name = "Intuicion";
            break;
        case "#06d6b0":
            name = "Complejidad";
            break;
        case "#26547c": 
            name = "Entender";
            break;

        default: 
            console.log("No color type on new card")
      }
      color.push([name,value]);
      slices.push({color: key});
    });

    return {
      title: title,
      type: "PieChart",
      data: color,
      options: {
        slices: slices,
      },
      columnNames: ["Cartes", "Percentage"],
    };
  }
}
