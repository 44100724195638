import { CdkStepper } from "@angular/cdk/stepper";
import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-custom-stepper",
  templateUrl: "./custom-stepper.component.html",
  styleUrls: ["./custom-stepper.component.css"],
  // This custom stepper provides itself as CdkStepper so that it can be recognized
  // by other components.
  providers: [{ provide: CdkStepper, useExisting: CustomStepperComponent }],
})
export class CustomStepperComponent extends CdkStepper implements OnInit {
  @Input()
  indexIfComplete: number;

  ngOnInit(): void {
    if (this.indexIfComplete == 2) {
      this.selectedIndex = 2;
    }
  }

  onClick(index: number): void {
    this.selectedIndex = index;
  }
}
