import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BoardRoutingModule } from './board-routing.module';
import { DiscoverComponent } from './discover/discover.component';
import { PrioritizeComponent } from './prioritize/prioritize.component';
import { SharedModule } from '../shared.module';
import { CountdownModule } from 'ngx-countdown';
import { newCardComponent } from './discover/new-card-dialog/newCard.dialog';
import { Canvas } from './prioritize/canvas-dialog/canvas.dialog';
import { endSessionDialog } from './prioritize/confirmation-dialog/confirmation.dialog';
import { imageUpload } from './prioritize/canvas-dialog/upload-canvas.dialog';
import { CanvasComponent } from './canvas/canvas.component';
import { WizardCanvas } from './canvas/wizard-canvas-dialog/wizard-canvas.dialog';
import { CustomStepperComponent } from './canvas/wizard-canvas-dialog/custom-stepper/custom-stepper.component';
import { DeleteCanvasDialog } from './prioritize/canvas-dialog/delete-canvas-dialog/delete-canvas.dialog';


@NgModule({
  declarations: [
    DiscoverComponent,
    PrioritizeComponent,
    newCardComponent,
    Canvas,
    endSessionDialog,
    imageUpload,
    CanvasComponent,
    WizardCanvas,
    CustomStepperComponent,
    DeleteCanvasDialog
  ],
  imports: [
    CommonModule,
    SharedModule,
    BoardRoutingModule,
    CountdownModule
  ]
})
export class BoardModule { }
