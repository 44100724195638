import { ClientHomeComponent } from './client-home/client-home.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { ClientsViewComponent } from './clients-view/clients-view.component';
import { SessionViewComponent } from './session-view/session-view.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: ClientsViewComponent,
    canActivate: [AuthGuard]
  },
  { 
    path: 'client', 
    component: ClientHomeComponent, 
    canActivate: [AuthGuard] 
  },
  { 
    path: 'session', 
    component: SessionViewComponent, 
    canActivate: [AuthGuard] 
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashBoardRoutingModule { }
