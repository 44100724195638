import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "./../shared.module";
import { AdministradorRoutingModule } from "./administrador-routing.module";
import { AdminPageComponent } from "./admin-page/admin-page.component";
import { DeletePartnerDialog } from "./admin-page/dialogs/delete-partner.dialog";
import { DeleteClientDialog } from "./admin-page/dialogs/delete-client.dialog";
import { DeleteSessionDialog } from "./admin-page/dialogs/delete-session.dialog";

@NgModule({
  declarations: [
    AdminPageComponent,
    DeletePartnerDialog,
    DeleteClientDialog,
    DeleteSessionDialog,
  ],
  imports: [CommonModule, AdministradorRoutingModule, SharedModule],
})
export class AdministradorModule {}
