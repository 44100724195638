import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatSelectionList } from "@angular/material/list";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { Participant } from "src/app/interfaces/participant";
import { Session } from "src/app/interfaces/session";
import { DataService } from "src/app/services/data.service";

@Component({
  selector: "session-name",
  template: `
    <div>
      <h4>{{ 'participant_management_title' | translate }}</h4>
      <hr />
      <p class="text-left">
        {{ 'participant_management1' | translate }}
      </p>
      <div id="participants_list">
        <mat-selection-list #participantsList [(ngModel)]="selectedOptions" (ngModelChange)="onNgModelChange($event)">
          <mat-list-option
            *ngFor="let participant of session.participants"
            [value]="participant"
          >
            <span>{{ participant.name }} : {{ participant.mail }}</span>
          </mat-list-option>
        </mat-selection-list>
      </div>
      <br>
      <h5>{{ 'new-participant' | translate }}</h5>
      <mat-form-field appearance="fill" class="material_inputs">
        <mat-label class="form-label">{{ 'participant-name' | translate }}</mat-label>
        <input
          matInput
          #participantName
          [formControl]="participantNameValidator"
          id="nameInput"
        />
        <mat-error *ngIf="participantNameValidator.invalid">
          {{ getErrorMessage() }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" class="material_inputs">
        <mat-label class="form-label">{{ "email" | translate }}</mat-label>
        <input
          matInput
          #participantMail
          placeholder="{{ 'example-mail' | translate }}"
          [formControl]="participantMailValidator"
          id="mailInput"
        />
        <mat-error *ngIf="participantMailValidator.invalid">
          {{ getErrorMessage() }}
        </mat-error>
      </mat-form-field>
      <mat-dialog-actions>
        <div id="buttons">
          <button
            mat-flat-button
            class="rounded-cards"
            color="warn"
            id="participant"
            [disabled]="!participantsChecked"
            (click)="removeSessionParticipants(participantsList)"
          >
            <span id="button-content">{{ "delete_posit" | translate }}</span>
          </button>
          <button
            mat-flat-button
            id="send-button"
            class="rounded-cards"
            [mat-dialog-close]="true"
            [disabled]="
              participantNameValidator.invalid ||
              participantMailValidator.invalid
            "
            color="primary"
            id="participant"
            (click)="
              addSessionParticipant(
                participantName.value,
                participantMail.value
              )
            "
          >
            <span id="button-content">{{ "accept" | translate }}</span>
          </button>
        </div>
      </mat-dialog-actions>
    </div>
  `,
  styleUrls: ["./dialog.css"],
})
export class sessionParticipantsDialog implements OnInit {
  durationInSeconds = 4;
  participantMailValidator = new FormControl("", [
    Validators.required,
    Validators.email,
  ]);
  participantsChecked = false;
  selectedOptions;
  participantNameValidator = new FormControl("", [Validators.required]);

  constructor(
    public translate: TranslateService,
    public dataService: DataService,
    public _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<sessionParticipantsDialog>,
    @Inject(MAT_DIALOG_DATA) public session
  ) {}

  ngOnInit() {}

  addSessionParticipant(name: string, mail: string) {
    let trans1 = "";
    if (
      this.participantNameValidator.hasError("required") ||
      this.participantMailValidator.hasError("required")
    ) {
      this.translate
        .get("obligatorio-entrar-valor") //ENTER VALUE REQUIRED
        .subscribe((translation) => {
          trans1 = translation;
        });
      this._snackBar.open(trans1, "", {
        duration: this.durationInSeconds * 1000,
        panelClass: ["error-snackbar"],
      });
      return;
    } else if (this.participantMailValidator.hasError("email")) {
      this.translate
        .get("email-invalido") //EMAIL NOT VALID
        .subscribe((translation) => {
          this._snackBar.open(translation, "", {
            duration: this.durationInSeconds * 1000,
            panelClass: ["error-snackbar"],
          });
        });
      return;
    }
    let p: Participant = {
      name: name,
      mail: mail,
    };
    this.dataService.setNewParticipant(p, this.session.sid);
  }

  onNgModelChange($event) {
    if ($event.length>0) {
      this.participantsChecked = true;
    } else {
      this.participantsChecked = false;
    }
  }

  removeSessionParticipants(participantsList: MatSelectionList) {
    participantsList.selectedOptions.selected.forEach((selectedOption) => {
      let participantId = selectedOption.value.pid;
      this.dataService.deleteParticipant(participantId, this.session.sid);
    });
    this.dialogRef.close();
  }

  getErrorMessage() {
    let trans = "";
    if (
      this.participantNameValidator.hasError("required") ||
      this.participantMailValidator.hasError("required")
    ) {
      this.translate
        .get("obligatorio-entrar-valor") //ENTER VALUE REQUIRED
        .subscribe((translation) => {
          trans = translation;
        });
      return trans;
    } else if (this.participantMailValidator.hasError("email")) {
      this.translate
        .get("email-invalido") //EMAIL NOT VALID
        .subscribe((translation) => {
          trans = translation;
        });
      return trans;
    } else {
      return "";
    }
  }
}
