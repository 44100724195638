import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { User } from "src/app/interfaces/user";
import { AuthService } from "src/app/auth/auth.service";
import { PhotoComponent } from "./dialogs/photo.component";
import { NameComponent } from "./dialogs/name.component";
import { PasswordComponent } from "./dialogs/password.component";
import { TipusClientComponent } from "./dialogs/tipus-client.component";
import { LanguageComponent } from "./dialogs/language.component";

@Component({
  selector: "app-account-page",
  templateUrl: "./account-page.component.html",
  styleUrls: ["./account-page.component.css"],
})
export class AccountPageComponent implements OnInit {

  userInfo: User = {};
  userDataInfo: User = {};

  constructor(
    public dialog: MatDialog,
    public authService: AuthService,
  ) {
  }

  ngOnInit(): void {
    this.authService.getUserInfo().subscribe((userInfo) => {
      this.userInfo = userInfo;
    });
    this.authService.getUserDataInfo().subscribe((userDataInfo) => {
      this.userDataInfo = userDataInfo;
    });
    this.authService.emit();
  }

  openDialogPhoto() {
    const dialogRef = this.dialog.open(PhotoComponent, {
      data: this.userInfo,
      minWidth: "400px",
      maxWidth: "500px",
      panelClass: "rounded-cards",
    });
    document.body.classList.add("cdk-global-scrollblock");
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        //var x = document.getElementById("formFile").value;
        console.log(result);
      }
    });
    document.body.classList.remove("cdk-global-scrollblock");
  }

  openDialogName() {
    const dialogRef = this.dialog.open(NameComponent, {
      data: this.userInfo,
      minWidth: "400px",
      maxWidth: "500px",
      panelClass: "rounded-cards",
    });
    document.body.classList.add("cdk-global-scrollblock");
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log(result);
      }
    });
    document.body.classList.remove("cdk-global-scrollblock");
  }

  openDialogPassword() {
    const dialogRef = this.dialog.open(PasswordComponent, {
      minWidth: "400px",
      maxWidth: "500px",
      panelClass: "rounded-cards",
    });
    document.body.classList.add("cdk-global-scrollblock");
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log(result);
      }
    });
    document.body.classList.remove("cdk-global-scrollblock");
  }

  openDialogClient() {
    const dialogRef = this.dialog.open(TipusClientComponent, {
      data: {
        userInfo: this.userInfo,
        userDataInfo: this.userDataInfo
      },
      minWidth: "1100px",
      maxWidth:"1100px",
      panelClass: "rounded-cards",
    });
    document.body.classList.add("cdk-global-scrollblock");
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log(result);
      }
    });
    document.body.classList.remove("cdk-global-scrollblock");
  }

  openDialogLanguage() {
    const dialogRef = this.dialog.open(LanguageComponent, {
      data: this.userDataInfo,
      minWidth: "400px",
      maxWidth: "500px",
      panelClass: "rounded-cards",
    });
    document.body.classList.add("cdk-global-scrollblock");
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log(result);
      }
    });
    document.body.classList.remove("cdk-global-scrollblock");
  }

  getLanguage() {
    switch (this.userDataInfo.language) {
      case 'es':
        return 'Español'
      case 'ca':
        return 'Català'
      case 'en':
        return 'English'
    
      default:
        break;
    }
  }
}