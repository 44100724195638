import { DataStorage } from "../../services/data.storage";
import { DataService } from "../../services/data.service";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { Customer } from "../../interfaces/customer";
import { Router } from "@angular/router";

import { FormControl, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialog3 } from "./confirmation-dialog/confirmation.dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { FactLevel } from "src/app/interfaces/nivell-facturacio";
import { Sector } from "src/app/interfaces/sector";
import { Worker } from "src/app/interfaces/worker";
import factlevels from "src/assets/standardData/factlevels.json";
import sectors from "src/assets/standardData/sectors.json";
import workers from "src/assets/standardData/workers.json";


@Component({
  selector: "app-clients-view",
  templateUrl: "./clients-view.component.html",
  styleUrls: ["./clients-view.component.css"],
})
export class ClientsViewComponent implements OnInit, OnDestroy {
  customerNameValidator = new FormControl("", [Validators.required]);
  customerSectorValidator = new FormControl("", [Validators.required]);
  customerLevelValidator = new FormControl("", [Validators.required]);


  fact: FactLevel[] = factlevels;
  sectors: Sector[] = sectors;
  workers: Worker[] = workers;
  customers = this.getCustomers();

  emptyValue = "";
  emptyValue2 = "";
  emptyValue3 = "";
  emptyValue4 = "";
  durationInSeconds = 4;
  sessionsxcustomer = new Map();

  constructor(
    public dataService: DataService,
    private _data: DataStorage,
    private router: Router,
    public translate: TranslateService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {}

  enter(e) {
    if (e.key == "Enter") {
      let focus = document.activeElement;
      if (!document.activeElement  || (focus.id != "add-customer-form-input" && focus.id != "customerButton")) {
        document.getElementById("add-customer-form-input").focus();
      } else if (focus.id == "add-customer-form-input") {
        document.getElementById("customerButton").focus();
      } else if (focus.id == "customerButton") {
        document.getElementById("add-customer-form-input").focus();
      }
    }
  }

  ngOnInit(): void {
    document.addEventListener("keypress", this.enter);
    this.dataService.getAllSessions().subscribe(data => {
      this.sessionsxcustomer = new Map();
      this.customers.forEach((customers)=>{
        customers.forEach(cus => {
          this.sessionsxcustomer.set(cus.cid, data.filter((obj) => obj.cid === cus.cid).length)

        })
      })
    });
  }

  ngOnDestroy(): void {
    document.removeEventListener("keypress", this.enter);
  }

  /**
   * add customer information to database
   *
   * @param customerName : string
   */
  addCustomer(customerName: string, sector: number, fact: number, worker: number) {
    let trans1 = "";
    if (this.customerNameValidator.hasError("required")) {
      this.translate
        .get("obligatorio-entrar-valor")
        .subscribe((translation) => {
          trans1 = translation;
        });
        this._snackBar.open(trans1, "", {
          duration: this.durationInSeconds * 1000,
          panelClass: ['error-snackbar']
        });
      return;
    }
    let customer: Customer;
    customer = {
      name: customerName,
      sector: sector,
      facturacio: fact,
      rangTreballadors: worker,
      logoURL: null,
    };
    this.dataService.setNewCustomer(customer);
    this.closeCreateCustomer();
  }

  /**
   * get all the information from the customers
   */
  getCustomers() {
    return this.dataService.getCustomers();
  }

  goClient(customerId) {
    this._data.data.cid = customerId;
    this._data.saveData();
    this.router.navigate(["client"]);
  }

  getErrorMessageSector() {
    let trans = "";
    if (this.customerSectorValidator.hasError("required")) {
      this.translate
        .get("obligatorio-entrar-valor") //ENTER VALUE REQUIRED
        .subscribe((translation) => {
          trans = translation;
        });
      return trans;
    }
  }

  getErrorMessageLevel() {
    let trans = "";
    if (this.customerLevelValidator.hasError("required")) {
      this.translate
        .get("obligatorio-entrar-valor") //ENTER VALUE REQUIRED
        .subscribe((translation) => {
          trans = translation;
        });
      return trans;
    }
  }

  getErrorMessageName() {
    let trans = "";
    if (this.customerNameValidator.hasError("required")) {
      this.translate
        .get("obligatorio-entrar-valor") //ENTER VALUE REQUIRED
        .subscribe((translation) => {
          trans = translation;
        });
      return trans;
    }
  }

  openCreateCustomer() {
    document
      .getElementById("create-costumer-id")
      .classList.toggle("transition");
  }

  closeCreateCustomer() {
    document
      .getElementById("create-costumer-id")
      .classList.toggle("transition");
      this.customerNameValidator.reset();
      this.customerSectorValidator.reset();
      this.customerLevelValidator.reset();
    this.emptyValue = "";
    this.emptyValue2 = "";
    this.emptyValue3 = "";
    this.emptyValue4 = "";
  }

  openDialog(customer) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmationDialog3, {
      data: customer,
      minHeight: '200px',
      maxWidth: '500px',
      panelClass: 'rounded-cards'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result){
        this.deleteCustomer(customer);
      }
    });
  }

  deleteCustomer(customer: Customer): void {
    if(this.sessionsxcustomer.get(customer.cid)>0) {
      this.translate
        .get("delete-customer-not-possible") //ENTER VALUE REQUIRED
        .subscribe((translation) => {
          this._snackBar.open(translation, "", {
            duration: this.durationInSeconds * 1000,
            panelClass: ['error-snackbar']
          });
        });
      
      return;
    }
    this.dataService.deleteCustomer(customer.cid);
  }
}
