import { Component, Inject, OnInit } from "@angular/core";
import { FormArray, FormBuilder, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { Framework } from "src/app/interfaces/framework";
import { DataService } from "src/app/services/data.service";

@Component({
  selector: "session-name",
  template: `
    <div>
      <h3>{{ "framework_rows_settings_title_dialog" | translate }}</h3>
      <hr />

      <div id="titol_horitzontal">
      <p class="text-left">
          {{ "framework_rows_settings_subtitle1_dialog" | translate }}
        </p>
        <p class="text-left">
          {{ "framework_rows_settings_subtitle2_dialog" | translate }}
        </p>
        
        <div class="row inputs_horitzontal" [formGroup]="form">
          <ng-container formArrayName="titolsFilesValidator">
            <ng-container *ngFor="let inputFila of getTitolsFilesValidator.controls; let i = index" [formGroupName]="i">
              <div [formGroup]="inputFila">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>{{ "fila" | translate }} {{ i+1}}</mat-label>
                  <input matInput formControlName="title" placeholder="{{ 'framework_row_title_dialog_placeholder' | translate }}" (change)="guardaArrayFiles(i, $event.target.value)">
                  <button mat-icon-button matSuffix (click)="deleteFila(i)" [disabled]="nFiles <= minRows">
                    <mat-icon>delete</mat-icon>
                  </button>
                  <mat-error>
                    {{ getErrorMessage(i) }}
                  </mat-error>
                </mat-form-field>
              </div>
            </ng-container>
          </ng-container>

        </div>
        <div class="row add_inputs_button">
          <button mat-mini-fab color="primary" matTooltip="{{ 'framework_add_row_dialog_btn_tooltip' | translate }}" matTooltipPosition="before" [disabled]="nFiles >= maxRows" (click)="addFila()">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>

      <mat-dialog-actions align="end">
        <button mat-button class="rounded-cards" mat-dialog-close>
          {{ "cancel" | translate }}
        </button>
        <button
          mat-flat-button
          id="send-button"
          class="rounded-cards"
          [mat-dialog-close]="true"
          color="primary"
          [disabled]="getTitolsFilesValidator.invalid || nFiles == 0"
          (click)="updateFrameworkRowsTitles()"
        >
          {{ "accept" | translate }}
        </button>
      </mat-dialog-actions>
    </div>
  `,
  styleUrls: ["../info-dialogs/dialog.css"],
})
export class FrameworkTitolsFilesDialog implements OnInit {
  maxRows = 6;
  minRows = 1;
  nFiles: number;

  arrayFiles;

  // Crear el FormGroupArray pels validators de cada input
  form = this.formBuilder.group({
    titolsFilesValidator: this.formBuilder.array([]),
  })

  constructor(
    public translate: TranslateService,
    public dataService: DataService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public session
  ) {}

  
  get getTitolsFilesValidator() {
    return this.form.controls["titolsFilesValidator"] as FormArray;
  }
  
  ngOnInit() {
    this.nFiles = 0;

    // Crear array amb 0 posicions inicials
    this.arrayFiles = new Array<string>(0);

    // Si ja existeix columnes afegirles al validator amb el valor que ve de Firebase
    // i posar-ho a l'array recent creat
    // sumar el numero de columnes que hi ha
    if (this.session.framework.nomFiles) {
      this.session.framework.nomFiles.forEach((value, index) => {
        const filaForm = this.formBuilder.group({
          title: [value, Validators.required],
        });
        this.getTitolsFilesValidator.push(filaForm);
        this.arrayFiles.push(value);
    
        this.nFiles += 1;
      });
    }
  }

  /**
   * crea el tipus de validator
   * 
   * afegeix validator a l'array de validators
   */
  addFila() {
    const filaForm = this.formBuilder.group({
      title: ['', Validators.required],
    });
    this.getTitolsFilesValidator.push(filaForm);

    this.nFiles += 1;
  }

  /**
   * elimina el validator de l'array de validators quan es clica al botó
   * 
   * elimina de l'array de columnes el nom de la columna de la posició corresponent
   * @param indexFila la posició de l'input que es vol borrar
   */
  deleteFila(indexFila: number) {
    this.getTitolsFilesValidator.removeAt(indexFila);
    
    // Remove element from arrayColumnes at position indexColumna
    this.arrayFiles.splice(indexFila, 1);

    this.nFiles -= 1;
  }

  /**
   * cada vegada que es deselecciona el input es guarda el titol de la fila a l'array local de `arrayFiles`
   * @param indexFila posició de l'input
   * @param titolFila títol de la columna introduida a l'input
   */
  guardaArrayFiles(indexFila: number, titolFila: string) {
    this.arrayFiles[indexFila] = titolFila;
  }

  /**
   * si existeix framework a la sessió s'assigna tots els camps de BBDD amb els corresponents
   * 
   * si NO existeix framework a la sessió, es crea un framework nou amb totes les dades corresponents
   * 
   * es fa una crida al DataService i una actualització a Firebase de la sessió amb el framework
   */
  updateFrameworkRowsTitles() {
    if (this.session.framework) {
      this.session.framework.numeroFiles = this.getTitolsFilesValidator.length;
      this.session.framework.nomFiles = this.arrayFiles;
    } else {
      let framework: Framework = {
        numeroFiles: this.getTitolsFilesValidator.length,
        nomFiles: this.arrayFiles
      }
      this.session.framework = framework;
    }
    this.dataService.updateSession(this.session);
  }

  getErrorMessage(index) {
    let trans1 = "";
    if (this.getTitolsFilesValidator.controls[index].get('title').hasError('required')) {
      this.translate
        .get("obligatorio-entrar-valor") //ENTER VALUE REQUIRED
        .subscribe((translation) => {
          trans1 = translation;
        });
      return trans1;
    }
  }
}
