import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { Customer } from "src/app/interfaces/customer";
import { DataService } from "src/app/services/data.service";
import { DataStorage } from "src/app/services/data.storage";

@Component({
  selector: "dynamic-comp",
  template: `
    <div>
      <h4>{{ "customer-sector2" | translate }}</h4>
      <hr />
      <p class="text-left">
        {{ "customer-sector3" | translate }} {{ this.data.name }}
      </p>
      <mat-form-field appearance="fill">
        <mat-label class="form-label">{{ "sector" | translate }}</mat-label>
        <mat-select #sector [formControl]="userValidator">
          <mat-option>--</mat-option>
          <mat-option
            *ngFor="let type of this.data.sectorList"
            [value]="type.id"
          >
            {{ type.text | translate }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="userValidator.invalid">
          {{ getErrorMessagePass() }}
        </mat-error>
      </mat-form-field>
      <mat-dialog-actions align="end">
        <button mat-button class="rounded-cards" mat-dialog-close>
          {{ "cancel" | translate }}
        </button>
        <button
          mat-flat-button
          id="send-button"
          class="rounded-cards"
          [mat-dialog-close]="true"
          [disabled]="userValidator.invalid"
          color="primary"
          (click)="updateCustomerSector(sector.value)"
        >
          {{ "accept" | translate }}
        </button>
      </mat-dialog-actions>
    </div>
  `,
  styleUrls: ["../client-home.component.css"],
})
export class SectorComponent implements OnInit {
  customer: Customer = {};

  userValidator = new FormControl(this.data.sector, [Validators.required]);

  constructor(
    public translate: TranslateService,
    private dataService: DataService,
    private _data: DataStorage,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {}

  updateCustomerSector(newCustomerSector: number) {
    let customer: Customer = {
      cid: this.data.cid,
      sector: newCustomerSector,
    };
    this.dataService.updateCustomer(customer);
  }

  getErrorMessagePass() {
    let trans1 = "";
    if (this.userValidator.hasError("required")) {
      this.translate
        .get("obligatorio-entrar-valor") //ENTER VALUE REQUIRED
        .subscribe((translation) => {
          trans1 = translation;
        });
      return trans1;
    }
  }
}
