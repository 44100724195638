import { MaterialModule } from './material/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CountdownModule } from 'ngx-countdown';
import { NgxCaptureModule } from 'ngx-capture';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleChartsModule } from 'angular-google-charts';

/**
 * This module is used to export translate module to others modules of the aplication.
 * Thanks this module, you dont need to inicialitzate the translate module every time.
 */

@NgModule({
  exports: [
    TranslateModule,
    FlexLayoutModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    GoogleChartsModule,
    NgxCaptureModule
  ]
})
export class SharedModule { }