import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ActivatedRouteSnapshot,
  CanActivate, CanActivateChild,
  CanDeactivate, CanLoad, Route,
  Router, RouterStateSnapshot, UrlSegment,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanDeactivate<unknown>, CanLoad {

  durationInSeconds = 4;

  constructor(
    public authservice: AuthService,
    public router: Router,
    private _snackBar: MatSnackBar
  ) {}

  /**
   * returns true when user can log in
   * @param route 
   * @param state guards
   * @returns true
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
  {
    let path = route.routeConfig.path;
    if (this.authservice.isLoggedIn != true) {
      this.router.navigate(['sign-in'],{queryParams:{'redirectURL':state.url}});
    }
    else if (this.authservice.isVerified != true && path != "verify-email-address") {
      this.router.navigate(['verify-email-address']);
    }
    else if (path == "admin" && !this.authservice.isSuperuser) {
      this.router.navigate(['dashboard']);
      this._snackBar.open("no ets admin", "", {
        duration: this.durationInSeconds * 1000,
        panelClass: ['error-snackbar']
      });
    } else {
      return true;
    }    
    
    //Si no està logat, va a sign in
    // else if (this.authservice.isLoggedIn !== true) {
    //   this.router.navigate(['sign-in'])
    // }
    //Si no te pla i la ruta es select pla, pla pendent o verify email
    // else if (this.authservice.havePlan != true && (path=="select-plan" || path=="plan-pendent" || path == "verify-email-address")) {
    //   return true;
    // }
    //NAVIGATE a "pla pendent" si no te pla i la ruta no es select pla, pla pendent o verify email
    // else if (this.authservice.havePlan != true && !(path=="select-plan" || path=="plan-pendent" || path == "verify-email-address")) {
    //   this.router.navigate(['plan-pendent']);
    // }
    //NAVIGATE a dashboard si te pla i la ruta es select pla, pla pendent o verify email
    // else if (this.authservice.havePlan == true && (path=="select-plan" || path=="plan-pendent" || path == "verify-email-address")) {
    //   this.router.navigate(['dashboard']);
    // }
    //NAVIGATE a dashboard si esta logat i la ruta es sign in o register user
    // else if (this.authservice.isLoggedIn == true && (path=="sign-in" || path=="register-user")) {
    //   this.router.navigate(['dashboard'])
    // }
    // return true;
  }

  /**
   * returns false when user cannot log in
   * @param childRoute 
   * @param state guards
   * @returns false
   */
  canNotActivate(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return false;
  }
  //DECIDES WHEN A CHILD ROUTE CAN CONTINUE NAVIGATION
  /**
   * decides when a chil route can continue navigation
   * @param childRoute 
   * @param state guards
   * @returns true
   */
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  /**
   * decides when a route can deactivate or not
   * @param component unknown
   * @param currentRoute 
   * @param currentState 
   * @param nextState guards
   * @returns true
   */
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  
  /**
   * decides when a child route can be loaded
   * @param route 
   * @param segments guards
   * @returns true
   */
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
}
