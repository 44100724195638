import { AnaliticsService } from "./../../services/analitics.service";
import { AccountControllerService } from "./../../services/accountController.service";
import { Session } from "../../interfaces/session";
import { DataStorage } from "../../services/data.storage";
import { Customer } from "../../interfaces/customer";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { DataService } from "src/app/services/data.service";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { FormControl, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialog } from "./confirmation-dialog/confirmation.dialog";
import { LogoComponent } from "./dialogs/logo.dialog";
import { CustomerNameComponent } from "./dialogs/cname.dialog";
import { SectorComponent } from "./dialogs/sector.dialog";
import { FactLevel } from "src/app/interfaces/nivell-facturacio";
import { Sector } from "src/app/interfaces/sector";
import factlevels from "src/assets/standardData/factlevels.json";
import sectors from "src/assets/standardData/sectors.json";
import workers from "src/assets/standardData/workers.json";
import { FacturacioComponent } from "./dialogs/facturacio.dialog";
import { WorkersComponent } from "./dialogs/workers.dialog";
import { AddSessionDialog } from "./dialogs/add-session.dialog";
import { NoDisponibleSessionsDialog } from "./dialogs/noDisponibleSessions.dialog";

export interface SessionTypes {
  value: number;
  viewValue: string;
}

@Component({
  selector: "app-client-home",
  templateUrl: "./client-home.component.html",
  styleUrls: ["./client-home.component.css"],
})
export class ClientHomeComponent implements OnInit, OnDestroy {
  sessionNameValidator = new FormControl("", [Validators.required]);
  sessionTypeValidator = new FormControl("", [Validators.required]);
  sessionDescriptionValidator = new FormControl("", [Validators.minLength(0)]);
  idiomaValidator = new FormControl("", [Validators.required]);

  emptyValue = "";
  emptyValue2 = "";

  durationInSeconds = 4;
  
  factList = new Map(factlevels.map(i => [i.id, i.text]));
  sectorList = new Map(sectors.map(i => [i.id, i.text]));
  workersList = new Map(workers.map(i => [i.id, i.text]));

  customer: Customer = {};
  session: Session = {};
  sessions: Observable<Session[]>;
  sessionTypes: SessionTypes[] = [
    //{ value: 1, viewValue: "Trial" },
    { value: 2, viewValue: "Standard" },
  ];
  idiomas = [
    { value: 'es', viewValue: "Español" },
    { value: 'en', viewValue: "English" },
    { value: 'ca', viewValue: "Català" },
  ];
  pieChart: any;
  groupedColumnChart: any;

  constructor(
    public dataService: DataService,
    private _data: DataStorage,
    private router: Router,
    public translate: TranslateService,
    public sessionAuth: AccountControllerService,
    public analitics: AnaliticsService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {}

  enter(e) {
    if (e.key == "Enter") {
      let focus = document.activeElement;
      if (!document.activeElement || (focus.id != "add-session-form-input" && focus.id != "selectSessionType" && focus.id != "discoverTime" && focus.id != "descriptionInput")) {
        document.getElementById("add-session-form-input").focus();
      } else if (focus.id == "add-session-form-input") {
        document.getElementById("discoverTime").focus();
      } else if (focus.id == "discoverTime") {
        document.getElementById("descriptionInput").focus();
      } else if (focus.id == "descriptionInput") {
        document.getElementById("session-button").focus();
      }
    }
  }

  ngOnInit(): void {
    let customerId = this._data.getCid();
    this.dataService.getCustomer(customerId).subscribe((data) => {
      this.customer = data;
      this.customer.cid = customerId;
      if(this.customer.trial) {
        this.sessionTypes = [
          { value: 1, viewValue: "Trial" },
        ];
      }
    });
    this.sessions = this.dataService.getSessions(customerId);
    
    //this.pieChart = this.analitics.getPieChart();
    this.analitics.getPieChartData(customerId).subscribe((chart) => {
      this.pieChart = chart;
      window.onresize
    })
    this.analitics.getGroupedColumnChartData(customerId).subscribe((chart) => {
      this.groupedColumnChart = chart;
    });

    document.addEventListener("keypress", this.enter);
  }

  ngOnDestroy(): void {
    document.removeEventListener("keypress", this.enter);
  }

  getDate() {
    return Date.now();
  }

  addSession(sessionName: string, sessionType: number, discoverTime: number, description: string, idioma: string) {
    let trans1 = "";
    if (this.sessionNameValidator.hasError("required")) {
      this.translate
        .get("enter_session_name")
        .subscribe((translation) => {
          trans1 = translation;
        });
      this._snackBar.open(trans1, "", {
        duration: this.durationInSeconds * 1000,
        panelClass: ['error-snackbar']
      });
      return;
    } else if (this.sessionTypeValidator.hasError('required')) {
      this.translate
        .get("enter_session_type")
        .subscribe((translation) => {
          trans1 = translation;
        });
      this._snackBar.open(trans1, "", {
        duration: this.durationInSeconds * 1000,
        panelClass: ['error-snackbar']
      });
      return;
    }
    //Comprovem si hi ha sessions disponibles
    let valida = sessionType==2? this.sessionAuth.sessionValid(sessionType) : true;
    if(!valida) {
      this._snackBar.open(this.translate.instant("no_sesions_dispon"), "", {
        duration: this.durationInSeconds * 1000,
        panelClass: ['error-snackbar']
      });
      this.closeCreateSession();
      return;
    }
    const dialogRef = this.dialog.open(AddSessionDialog, {
      data: this.customer.name,
      minWidth: "400px",
      maxWidth: "500px",
      panelClass: "rounded-cards",
    });
    document.body.classList.add("cdk-global-scrollblock");
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log(1, result);
        let session: Session;
        session = {
          name: sessionName,
          description: description,
          discoverTime: discoverTime * 60,
          counterState: discoverTime * 60,
          tipusSessio: sessionType,
          lang: idioma,
          valida: valida,
          numeroCartesCarregades: 0,
          numeroCartesTrobades: 0,
          numeroCartesBorrades: 0,
          estat: 0,
          serveisActivats: {
            1: false,
          },
        };
        this.dataService.setNewSession(session, this.customer.cid);
        this.closeCreateSession();
      } else {
        console.log(2, result);
      } 
    });
    document.body.classList.remove("cdk-global-scrollblock");

    //missatge 
    /*
    //comprovacio que es pot crear la sessio
    if (!this.sessionAuth.canCreateSession(sessionType)) {
      this.translate
        .get("type-session-not-possible") //ENTER VALUE REQUIRED
        .subscribe((translation) => {
          this._snackBar.open(translation, "", {
            duration: this.durationInSeconds * 1000,
            panelClass: ['error-snackbar']
          });
        });
      return;
    }*/
  }

  goSession(sessionId) {
    this._data.data.sid = sessionId;
    this._data.saveData();
    this.router.navigate(["session"]);
  }

  deleteSession(sess: Session): void {
    if(sess.estat > 0) {
      this.translate
        .get("delete-session-not-possible") //ENTER VALUE REQUIRED
        .subscribe((translation) => {
          this._snackBar.open(translation, "", {
            duration: this.durationInSeconds * 1000,
            panelClass: ['error-snackbar']
          });
        });
      
      return;
    }
    this.dataService.deleteSession(sess);
  }

  openDialog(session: Session) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: session,
      minHeight: '200px',
      maxWidth: '500px',
      panelClass: 'rounded-cards'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteSession(session);
        this.router.navigate(["client"]);
      }
    });
  }
  
  getErrorMessageSessionName() {
    let trans = "";
    if (this.sessionNameValidator.hasError("required")) {
      this.translate
        .get("enter_session_name")
        .subscribe((translation) => {
          trans = translation;
        });
      return trans;
    }
  }

  getErrorMessageSessionType() {
    let trans = "";
    if (this.sessionTypeValidator.hasError("required")) {
      this.translate
        .get("enter_session_type")
        .subscribe((translation) => {
          trans = translation;
        });
      return trans;
    }
  }

  getErrorMessageIdioma() {
    return "";
  }

  openCreateSession() {
    //Comprovem si hi ha sessions disponibles
    let valida = this.sessionAuth.sessionValid(2);
    if(!valida && !this.customer.trial) {
      const dialogRef = this.dialog.open(NoDisponibleSessionsDialog, {
        data: { window: window},
        minWidth: "400px",
        maxWidth: "500px",
        panelClass: "rounded-cards",
      });
      document.body.classList.add("cdk-global-scrollblock");
      dialogRef.afterClosed().subscribe((result) => {
        
      });
      document.body.classList.remove("cdk-global-scrollblock");
      return;
    } else {
      document.body.style.overflow = "hidden";
      document.getElementById("create-session-id").classList.toggle("transition");
    }
  }

  closeCreateSession() {
    document.body.style.overflow = "";
    document.getElementById("create-session-id").classList.toggle("transition");
    this.sessionNameValidator.reset()
    this.emptyValue = "";
    this.emptyValue2 = "";
  }

  // FUNCIONS OBRIR DIALEGS
  openlogoDialog() {
    const dialogRef = this.dialog.open(LogoComponent, {
      data: {
        cid: this.customer.cid,
        logoURL: this.customer.logoURL,
      },
      minWidth: "400px",
      maxWidth: "500px",
      panelClass: "rounded-cards",
    });
    document.body.classList.add("cdk-global-scrollblock");
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        //var x = document.getElementById("formFile").value;
        console.log(result);
      }
    });
    document.body.classList.remove("cdk-global-scrollblock");
  }

  openCustomerNameDialog() {
    const dialogRef = this.dialog.open(CustomerNameComponent, {
      data: this.customer,
      minWidth: "400px",
      maxWidth: "500px",
      panelClass: "rounded-cards",
    });
    document.body.classList.add("cdk-global-scrollblock");
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log(result);
      }
    });
    document.body.classList.remove("cdk-global-scrollblock");
  }

  openSectorDialog() {
    const dialogRef = this.dialog.open(SectorComponent, {
      data: {
        cid: this.customer.cid,
        name: this.customer.name,
        sector: this.customer.sector,
        sectorList: sectors,
      },
      minWidth: "400px",
      maxWidth: "500px",
      panelClass: "rounded-cards",
    });
    document.body.classList.add("cdk-global-scrollblock");
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log(result);
      }
    });
    document.body.classList.remove("cdk-global-scrollblock");
  }

  openFacturacioDialog() {
    const dialogRef = this.dialog.open(FacturacioComponent, {
      data: {
        cid: this.customer.cid,
        name: this.customer.name,
        facturacio: this.customer.facturacio,
        factList: factlevels,
      },
      minWidth: "400px",
      maxWidth: "500px",
      panelClass: "rounded-cards",
    });
    document.body.classList.add("cdk-global-scrollblock");
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log(result);
      }
    });
    document.body.classList.remove("cdk-global-scrollblock");
  }

  openTreballadorsDialog() {
    const dialogRef = this.dialog.open(WorkersComponent, {
      data: {
        cid: this.customer.cid,
        name: this.customer.name,
        worker: this.customer.rangTreballadors,
        workers: workers,
      },
      minWidth: "400px",
      maxWidth: "500px",
      panelClass: "rounded-cards",
    });
    document.body.classList.add("cdk-global-scrollblock");
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log(result);
      }
    });
    document.body.classList.remove("cdk-global-scrollblock");
  }

}

