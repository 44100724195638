import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "delete-canvas-dialog",
  templateUrl: "./delete-canvas.dialog.html",
  styleUrls: ["./delete-canvas.dialog.scss"],
})
export class DeleteCanvasDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public cardName) {}
}
