import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "src/app/auth/auth.service";

@Component({
  selector: "app-sign-in",
  templateUrl: "./sign-in.component.html",
  styleUrls: ["./sign-in.component.css", "../acces-module.css"],
})
export class SignInComponent implements OnInit, OnDestroy {
  emailValidator = new FormControl("", [Validators.required, Validators.email]);
  passwordValidator = new FormControl("", [Validators.required]);
  hide = true;
  showPass = false;
  redirectURL = null;

  constructor(
    public authService: AuthService,
    public translate: TranslateService,
    private route: ActivatedRoute, 
  ) {}

  enter(e) {
    if (e.key == "Enter") {
      let focus = document.activeElement;
      if (!document.activeElement || (focus.id != "mailInput" && focus.id != "passInput")) {
        document.getElementById("mailInput").focus();
      } else if (focus.id == "mailInput") {
        document.getElementById("passInput").focus();
      } else if (focus.id == "passInput") {
        document.getElementById("loginButton").focus();
      }
    }
  }
  

  ngOnInit(): void {
    document.addEventListener("keypress", this.enter);
    let params = this.route.snapshot.queryParams;
    if (params['redirectURL']) {
        this.redirectURL = params['redirectURL'];
    }

  }

  ngOnDestroy(): void {
    document.removeEventListener("keypress", this.enter);
  }

  /**
   * check if there is an error
   * @returns translation string
   */
  getErrorMessageEmail() {
    let trans = "";
    if (this.emailValidator.hasError("required")) {
      this.translate
        .get("obligatorio-entrar-valor") //ENTER VALUE REQUIRED
        .subscribe((translation) => {
          trans = translation;
        });
      return trans;
    } else if (this.emailValidator.hasError("email")) {
      this.translate
        .get("email-invalido") //EMAIL NOT VALID
        .subscribe((translation) => {
          trans = translation;
        });
      return trans;
    } else {
      return "";
    }
  }

  getErrorMessagePass() {
    let trans = "";
    if (this.passwordValidator.hasError("required")) {
      this.translate
        .get("obligatorio-entrar-valor") //ENTER VALUE REQUIRED
        .subscribe((translation) => {
          trans = translation;
        });
      return trans;
    }
  }

  signIn(email, password) {
    if (this.redirectURL) {        
      this.authService.SignIn(email,password,this.redirectURL)
    } else {
      this.authService.SignIn(email,password)
    }
  }
}
