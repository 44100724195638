import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import firebase from "firebase";
import { take } from "rxjs/operators";
import { AuthService } from "src/app/auth/auth.service";

@Component({
  selector: "app-activation",
  templateUrl: "./activation.component.html",
  styleUrls: ["./activation.component.css"],
})
export class ActivationComponent implements OnInit {
  constructor(
    private route: ActivatedRoute, 
    private router: Router,
    private authService: AuthService
    ) {}

  msg: string;
  code: string;
  waiting = true;
  error: number;

  ngOnInit() {
    /*setTimeout(() => {
      
    }, 5000); */

    this.route.queryParams.subscribe((params) => {
      this.code = params.code;
      var activationRef = firebase.functions().httpsCallable('activateSessions');
      this.authService.getUserDataInfo().pipe(take(1)).subscribe((userDataInfo) => {
        console.log("auth");
        let data = {
          uid: userDataInfo.uid,
          code: this.code,
        }
        activationRef(data).then((result) => {
          this.error = result.data;
          if (result.data == 0) {
            this.msg = "activacion_correcta";
            this.waiting = false;
          } else if (result.data == 1) {
            console.log("codi no valid")
            this.msg = "codigo_sessiones_no_valido";
            this.waiting = false;
          } else if (result.data == 2) {
            this.msg = "correo_sessiones_no_valido";
            console.log("aquest no es el correu amb el que has comprat les sessions")
            this.waiting = false;
          } else {
            this.msg = "error-not-identified";
            this.error = 3;
            this.waiting = false;
          }
        }).catch((error) => {
          this.code = error;
          this.msg = "error-not-identified";
          this.error = 3;
          this.waiting = false;
        });
      });      
    });
  }

  goToDashboard() {
    this.router.navigate(['dashboard']);
  }
}
