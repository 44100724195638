import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from "../auth/auth.guard";
import { AccountPageComponent } from './account-page/account-page.component';

const routes: Routes = [
    { 
        path: 'account', 
        component: AccountPageComponent, 
        canActivate: [AuthGuard] 
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class UserRoutingModule { }