import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "src/app/auth/auth.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DataService } from "src/app/services/data.service";
import { Card } from "src/app/interfaces/card";

@Component({
  selector: "canvas-dialog",
  template: `
    <div>
      <h4>{{ "select-canvas" | translate }}</h4>
      <hr />
      <div class="mb-3">
        <label for="formFile" class="form-label">
        {{ "select-canvas2" | translate }}
        </label>
        <input
          class="form-control"
          type="file"
          id="formFile"
          #file
          [formControl]="canvasValidator"
        />
        <mat-error *ngIf="canvasValidator.invalid">
          {{ getErrorMessagePass() }}
        </mat-error>
      </div>
      <mat-dialog-actions>
        <div class="photo_buttons">
          <button
            mat-flat-button
            class="rounded-cards"
            id="add-photo"
            mat-dialog-close
            *ngIf="carta.canvasURL != null"
            (click)="deleteCanvas()"
            color='warn'
          >
            {{ "delete-canvas" | translate }}
          </button>
          <button
            mat-flat-button
            class="rounded-cards"
            id="add-photo"
            (click)="saveFile()"
            color="primary"
            [disabled]="canvasValidator.invalid"
          >
            <span id="button-content" *ngIf="carta.canvasURL == null">{{
              "add-canvas" | translate
            }}</span>
            <span id="button-content" *ngIf="carta.canvasURL != null">{{
              "update-canvas" | translate
            }}</span>
          </button>
        </div>
      </mat-dialog-actions>
    </div>
  `,
  styleUrls: ["./canvas.dialog.scss"],
})
export class imageUpload implements OnInit {
  canvasValidator = new FormControl("", [Validators.required]);
  file;
  carta: Card;

  constructor(
    public translate: TranslateService,
    public dataService: DataService,
    public authService: AuthService,
    public dialogRef: MatDialogRef<imageUpload>,
    @Inject(MAT_DIALOG_DATA) public cardInfo
  ) {}

  ngOnInit(): void {
    this.carta = this.cardInfo.card as Card;
    let element = document.getElementById("formFile") as HTMLInputElement;
    let component = this;
    element.onchange = function (event) {
      component.file = element.files[0];
    };
  }

  deleteCanvas() {
    this.dataService.deleteCanvas(this.cardInfo.gid, this.carta.cid, this.cardInfo.sid, this.carta.canvasURL);
  }

  saveFile() {
    let teCanvas = this.carta.canvasURL? true : false;
    this.dataService.uploadCanvas(this.file, this.cardInfo.gid, this.carta.cid, this.cardInfo.sid, teCanvas, this.carta.canvasURL);
    this.dialogRef.close();
  }

  getErrorMessagePass() {
    let trans1 = "";
    if (this.canvasValidator.hasError("required")) {
      this.translate
        .get("obligatorio-entrar-valor") //ENTER VALUE REQUIRED
        .subscribe((translation) => {
          trans1 = translation;
        });
      return trans1;
    }
  }
}
