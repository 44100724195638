import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from 'src/app/interfaces/user';
import { MatDialog } from "@angular/material/dialog";
import { Router } from '@angular/router';


@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.css', '../acces-module.css']
})
export class VerifyEmailComponent implements OnInit {

  userInfo: User = {};
  userDataInfo: User = {};

  constructor(
    public authService: AuthService,
    public translate: TranslateService,
    public dialog: MatDialog,
    private route: Router
  ) { }

  ngOnInit(): void {
    this.authService.getUserInfo().subscribe(user => {
      this.userInfo = user;
    });
    this.authService.getUserDataInfo().subscribe((userDataInfo) => {
      this.userDataInfo = userDataInfo;
    });
    this.authService.emit();
  }

  goDashboard() {
    this.route.navigate(['dashboard']);
  }

}
