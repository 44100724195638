import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import {
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { Card } from "src/app/interfaces/card";
import { FrameworkVegades } from "src/app/interfaces/framework-vegades";
import { AccountControllerService } from "src/app/services/accountController.service";
import { DataService } from "src/app/services/data.service";

@Component({
  selector: "dynamic-comp",
  templateUrl: "./new-card.dialog.html",
  styleUrls: ["./newCard.dialog.css"],
})
export class newCardComponent implements OnInit {
  durationInSeconds = 4;
  nameValidator = new FormControl("", [Validators.required]);
  cardFramePosition: FrameworkVegades;
  click: string;

  constructor(
    public translate: TranslateService,
    private dataService: DataService,
    private accountController: AccountControllerService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<newCardComponent>
  ) {}

  ngOnInit(): void {
    let dialogConfig = new MatDialogConfig();
    if (!this.data.session.framework) {
      dialogConfig.width = "fit-content";
      this.dialogRef.updateSize(dialogConfig.width);
    } else {
      if (!this.data.session.framework.quadratsClicats) {
        this.data.session.framework.quadratsClicats = [];
      }
    }


    this.showCardDigital(this.data.carta);
  }

  mostraElement(cols: string, files: string, element: HTMLElement, indexCols: number, indexFiles: number) {
    let click = cols + "-" + files;
    let clicPosition = indexFiles * this.data.session.framework.numeroColumnes + indexCols;

    if (element.id == "clicat") {
      this.cardFramePosition = null;
      element.id = "";
      element.style.backgroundColor = "transparent";
    } else {
      this.cardFramePosition = {
        posicio: clicPosition,
        titol: click,
      };
      var ele = document.getElementById("clicat");
      if (ele) {
        ele.id = "";
        ele.style.backgroundColor = "transparent";
      }
      element.id = "clicat";
      element.style.backgroundColor = this.data.carta.aiColor;
    }
  }

  counter(i: number) {
    return new Array(i);
  }

  showCardDigital(card: Card) {
    let cos = document.getElementById("cos-carta-mostra_pu") as HTMLDivElement;
    let image = document.getElementById(
      "img-carta-mostra_pu"
    ) as HTMLImageElement;
    let text = document.getElementById(
      "text-carta-mostra_pu"
    ) as HTMLParagraphElement;

    let cardVerbsString = this.translate.instant(card.verbs[0]);
    card.verbs.forEach((verb, index) => {
      if (index != 0) cardVerbsString += ", " + this.translate.instant(verb);
    });
    switch (this.translate.getDefaultLang()) {
      case "es":
        text.innerHTML = card.es + "<br><br><b>" + cardVerbsString + "</b>";
        break;
      case "en":
        text.innerHTML = card.eng + "<br><br><b>" + cardVerbsString + "</b>";
        break;
      case "ca":
        text.innerHTML = card.ca + "<br><br><b>" + cardVerbsString + "</b>";
        break;
      default:
        text.innerHTML = card.es + "<br><br><b>" + cardVerbsString + "</b>";
        break;
    }
    cos.style.backgroundColor = card.aiColor;
    if (card.aiColor == "#06d6b0") {
      image.src = "../../assets/images/aicomplex.svg";
      text.style.color = "white";
    } else if (card.aiColor == "#26547c") {
      image.src = "../../assets/images/aiunderstand.svg";
      text.style.color = "white";
    } else {
      image.src = "../../assets/images/aiintuition.svg";
      text.style.color = "#26547c";
    }
  }

  addCard(name: string) {
    let text;
    switch (this.translate.getDefaultLang()) {
      case "es":
        text = this.data.carta.es;
        break;
      case "en":
        text = this.data.carta.eng;
        break;
      case "ca":
        text = this.data.carta.ca;
        break;
      default:
        text = this.data.carta.es;
        break;
    }

    let cardTemp: Card = {
      cardId: this.data.carta.cardId,
      color: this.data.carta.aiColor,
      name: name,
      text: text,
      verbs: this.data.carta.verbs,
      // si cardFramePosition existeix s'assigna el camp frameworkPosition, sino no
      ...(this.cardFramePosition && {
        frameworkPosition: this.cardFramePosition,
      }),
    };

    let permis = this.accountController.pucCarregarCarta(this.data.session);
    if (permis[0]) {
      this.dataService.setNewCard(this.data.sesionId, cardTemp);
      if (this.data.session.estat == 0) {
        this.dataService.updateSession({
          sid: this.data.sesionId,
          estat: 1,
          dataInici: Date.now(),
        });
      }

      if (this.data.session.framework) {
        let updateItem = this.data.session.framework.quadratsClicats.find((item) => item.posicio === this.cardFramePosition.posicio);
        if (updateItem) {
          updateItem.vegades++;
          let index = this.data.session.framework.quadratsClicats.indexOf(updateItem);
          this.data.session.framework.quadratsClicats[index] = updateItem;
        } else {
          this.cardFramePosition.vegades = 1;
          this.data.session.framework.quadratsClicats.push(
            this.cardFramePosition
          );
        }


        this.dataService.actualitzarFramework(
          this.data.sesionId,
          this.data.session.framework
        );
      }
    } else {
      this._snackBar.open(this.translate.instant(permis[1]), "", {
        duration: this.durationInSeconds * 1000,
        panelClass: ["error-snackbar"],
      });
    }
  }

  enter(e) {
    if (e.key == "Enter") {
      let focus = document.activeElement;
      if (
        !document.activeElement ||
        (focus.id != "nameInput" && focus.id != "send-button")
      ) {
        document.getElementById("nameInput").focus();
      } else if (focus.id == "nameInput") {
        document.getElementById("send-button").focus();
      } else if (focus.id == "send-button") {
        document.getElementById("nameInput").focus();
      }
    }
  }

  getErrorMessagePass() {
    let trans1 = "";
    if (this.nameValidator.hasError("required")) {
      this.translate
        .get("obligatorio-entrar-valor") //ENTER VALUE REQUIRED
        .subscribe((translation) => {
          trans1 = translation;
        });
      return trans1;
    }
  }

  ngOnDestroy(): void {
    document.removeEventListener("keypress", this.enter);
  }
}
