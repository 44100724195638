import { Component, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "src/app/auth/auth.service";
import { FormControl, Validators } from "@angular/forms";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.css", "../acces-module.css"],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  emailValidator = new FormControl("", [Validators.required, Validators.email]);

  constructor(
    public authService: AuthService,
    public translate: TranslateService
  ) {}

  enter(e) {
    if (e.key == "Enter") {
      let focus = document.activeElement;
      if (
        !document.activeElement ||
        (focus.id != "mailInput" && focus.id != "send-button")
      ) {
        document.getElementById("mailInput").focus();
      } else if (focus.id == "mailInput") {
        document.getElementById("send-button").focus();
      } else if (focus.id == "send-button") {
        document.getElementById("mailInput").focus();
      }
    }
  }

  ngOnInit(): void {
    document.addEventListener("keypress", this.enter);
  }

  ngOnDestroy(): void {
    document.removeEventListener("keypress", this.enter);
  }

  /**
   * check if there is an error
   * @returns translation string
   */
  getErrorMessageEmail() {
    let trans = "";
    if (this.emailValidator.hasError("required")) {
      this.translate
        .get("obligatorio-entrar-valor") //ENTER VALUE REQUIRED
        .subscribe((translation) => {
          trans = translation;
        });
      return trans;
    } else if (this.emailValidator.hasError("email")) {
      this.translate
        .get("email-invalido") //EMAIL NOT VALID
        .subscribe((translation) => {
          trans = translation;
        });
      return trans;
    } else {
      return "";
    }
  }
}
