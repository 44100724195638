import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AuthService } from "src/app/auth/auth.service";

@Component({
  selector: "confirmation",
  template: `
    <div>
      <h1 mat-dialog-title>
        <strong>Eliminar partner / usuari</strong>
      </h1>

      <div mat-dialog-content class="mat-typography">
        <div class="alert alert-danger d-flex align-items-center" role="alert">
          <mat-icon class="bi flex-shrink-0 me-2" width="24" height="24"
            >warning</mat-icon
          >
          <div>
            <strong
              >Atenció, aquesta acció és definitiva i no és reversible</strong
            >
          </div>
        </div>
        <h3>
          Estàs a punt de borrar el partner <strong>{{ this.user.displayName }}</strong>, estàs segur?
          Això borrarà tots els clients i sessions associades a aquest partner. 
        </h3>
      </div>
      <mat-dialog-actions align="end">
        <button
          mat-button
          class="rounded-cards"
          mat-dialog-close
          cdkFocusInitial
        >
          {{ "Cancel·lar" | translate }}
        </button>
        <button
          mat-flat-button
          class="rounded-cards"
          [mat-dialog-close]="true"
          color="warn"
          (click)="deletePartner()"
        >
          {{ "Acceptar" | translate }}
        </button>
      </mat-dialog-actions>
    </div>
  `,
  styleUrls: ["./dialog.css"],
})
export class DeletePartnerDialog {
  constructor(
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public user
  ) {}

  deletePartner() {
    this.authService.deleteUser(this.user.uid);
  }
}
