import { EventEmitter, Output } from "@angular/core";
import { Injectable } from "@angular/core";

export interface Data {
  uid?: string;
  cid?: string;
  sid?: string;
}

@Injectable({
  providedIn: "root",
})
export class DataStorage {
  @Output() fire: EventEmitter<any> = new EventEmitter();

  public data: Data = {};
  public constructor() {}

  saveData() {
    sessionStorage.setItem("data", JSON.stringify(this.data));
  }

  getUid(): string {
    this.data = JSON.parse(sessionStorage.getItem("data"));
    return this.data.uid;
  }

  getCid(): string {
    this.data = JSON.parse(sessionStorage.getItem("data"));
    return this.data.cid;
  }

  getSid(): string {
    this.data = JSON.parse(sessionStorage.getItem("data"));
    return this.data.sid;
  }

  setToolbarDisplay(t: boolean) {
    this.fire.emit(t);
  }

  getEmittedValue() {
    return this.fire;
  }
}
