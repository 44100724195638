import { TranslateService } from "@ngx-translate/core";
import { EventEmitter, Injectable, NgZone, Output } from "@angular/core";
import { User } from "../interfaces/user";
import { Customer } from "../interfaces/customer";
//import { auth } from 'firebase/app';
import { AngularFireAuth } from "@angular/fire/auth";
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from "@angular/fire/firestore";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import firebase from "firebase/app";
import { take } from 'rxjs/operators';
import { AngularFireFunctions } from "@angular/fire/functions";

@Injectable({
  providedIn: "root",
})
export class AuthService {
    
  durationInSeconds = 4;
  private user: any; //save logged user data
  private userData: any;
  private userSubscription: Subscription;
  @Output() userInfoEmiter: EventEmitter<any> = new EventEmitter();
  @Output() userDataInfoEmiter: EventEmitter<any> = new EventEmitter();

  constructor(
    private afs: AngularFirestore,
    private afAuth: AngularFireAuth,
    private functions: AngularFireFunctions,
    private router: Router,
    private ngZone: NgZone,
    private translate: TranslateService,
    private _snackBar: MatSnackBar,
  ) {
    /**
     * saving user data in localstorage when logged in
     * and setting up null when logged out. 
     * -- ALSO -- save user data to authstate to validate 
     * data at all times
     */
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.user = user;
        user.reload();
        this.userInfoEmiter.emit(this.userInfo);
        this.userSubscription = this.afs.doc(`users/${user.uid}`).valueChanges({idFiels:"uid"}).subscribe(data => {
          this.userData = data as User;
          this.userDataInfoEmiter.emit(this.userDataInfo);
          localStorage.setItem("userData", JSON.stringify(this.userData));
          JSON.parse(localStorage.getItem("userData"));
        });
        localStorage.setItem("user", JSON.stringify(this.user));
        JSON.parse(localStorage.getItem("user"));
      } else {
        if(this.userSubscription) this.userSubscription.unsubscribe();
        this.userData = null;
        localStorage.setItem("user", null);
        JSON.parse(localStorage.getItem("user"));
        localStorage.setItem("userData", null);
        JSON.parse(localStorage.getItem("userData"));
      }
    });
  }

  /**
   * Sign In function
   * @param email : any
   * @param password : any
   */
  SignIn(email: any, password: any, redirectURL?) {
    /** 
     * to sign in we use the 'auth' service to authenticate to the database
     * and the user info are stored in the service for use afterwards 
     */
    this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.SetUser2(result.user);
      })
      .catch((error: { code: any }) => {
        this.translate.get(error.code).subscribe((translation) => {
          this._snackBar.open(translation, "", {
            duration: this.durationInSeconds * 1000,
            panelClass: ['error-snackbar']
          });
          return;
        });
      });
    //Aquesta marranada serveix per a que estiguin les dades al local storage a temps
    // sino no hi ha temps. Per a futur buscar una manera millor
    setTimeout(() => {
      if (redirectURL) {        
        this.router.navigateByUrl(redirectURL,)
        .catch(() => this.router.navigate(['dashboard']));
      } else {
        this.router.navigate(["dashboard"]);
      }
    }, 1000);
  }

  /**
   * sign up with email and password
   * 
   * @param email : any
   * @param password : any
   * @returns 
   */
  SignUp(email: any, password: any, confirmpass: any, username: string) {
    if (password!=confirmpass){
      this.translate.get("password-not-match").subscribe((translation) => {

        this._snackBar.open(translation, "", {
          duration: this.durationInSeconds * 1000,
          panelClass: ['error-snackbar']
        });
      });
      return;
    }
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then((result) => {
        result.user.updateProfile({
          displayName: username,
          photoURL: ''
        }).then(() => {
          let user = result.user as User;
          user.accountType=3;
          user.planPendent=false;
          user.requestedPlan=null;
          user.serveisDisponibles = [];
          user.sessions=0;
          user.sessionsTrial=0;
          user.super=false;
          user.language='es';
          this.SetUser(user);
          this.SendVerificationMail();
        })
        return result.user.uid;
      })
      //display if there is any error
      .catch((error: { code: any }) => {
        this.translate.get(error.code).subscribe((translation) => {
          this._snackBar.open(translation, "", {
            duration: this.durationInSeconds * 1000,
            panelClass: ['error-snackbar']
          });
        });
      });
  }

  /**
   * send verification email when user successfully logged in
   * 
   * @returns : sendEmailVerification() function
   */
  async SendVerificationMail() {
    return (await this.afAuth.currentUser).sendEmailVerification().then(() => {
      this.router.navigate(["verify-email-address"]);
      this.translate.get("verification-sent").subscribe((translation) => {
        this._snackBar.open(translation, "", {
          duration: this.durationInSeconds * 1000,

        });
      });
    });
  }

  /**
   * reset password --> call function ForgotPassword
   * 
   * @param passwordResetEmail : any
   * @returns : sendPasswordResetEmail function
   */
  ForgotPassword(passwordResetEmail: any) {
    let trans = "";
    return this.afAuth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        this.translate
        .get("password-reset")
        .subscribe((translation) => {
          trans = translation;
        });
        this._snackBar.open(trans, "", {
          duration: this.durationInSeconds * 1000,
        });
      })
      .catch((error: { code: any }) => {
        this.translate.get(error.code).subscribe((translation) => {
          this._snackBar.open(translation, "", {
            duration: this.durationInSeconds * 1000,
            panelClass: ['error-snackbar']
          });
        });
      });
  }

  /**
   * returns true when user have plan 
   */
   get havePlan(): boolean {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData == null) return false;
    else if (userData.accountType == 0) return false;
    return true;
  }

  /**
   * returns true when user is logged in
   */
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user == null) return false;
    return true;
  }

  /**
   * returns true when user is logged in and is superuser
   */
   get isSuperuser(): boolean {
    const user = JSON.parse(localStorage.getItem("userData"));
    if(user!== null && user.super) return true;
    return false;
  }

  /**
   * returns true when email is verified
   */
  get isVerified(): boolean {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user == null) return false;
    if (user.emailVerified == null) return false;
    return user.emailVerified;
  }

  /**
   * gets user id from database
   */
  get userId(): string {
    const user = JSON.parse(localStorage.getItem("userData"));
    if(!user ) return null;
    return user.uid;
  }

  get userInfo(): User {
    let user: User = {}
    if(!this.user) return null;
    user = {
      email: this.user.email,
      displayName: this.user.displayName,
      photoURL: this.user.photoURL,
      // language: this.translate.setDefaultLang('es'),
    };
    return user;
  }
  getUserInfo() {
    return this.userInfoEmiter;
  }

  private get userDataInfo(): User {
    let user: User = {}
    if(!this.userData) return null;
    user = {
      uid: this.userData.uid,
      accountType: this.userData.accountType,
      sessions: this.userData.sessions,
      sessionsTrial: this.userData.sessionsTrial,
      requestedPlan: this.userData.requestedPlan,
      serveisDisponibles: this.userData.serveisDisponibles,
      disponibleSessions: this.userData.disponibleSessions,
      language: this.userData.language,
    };
    return user;
  }
  getUserDataInfo() {
    return this.userDataInfoEmiter;
    
  }

  emit() {
    this.userInfoEmiter.emit(this.userInfo);
    this.userDataInfoEmiter.emit(this.userDataInfo);
  }

  // SIGN IN WITH GOOGLE
  /*GoogleAuth() {
    return this.AuthLogin(new auth.GoogleAuthProvider());
  }*/

  /**
   * auth login to run auth providers
   * 
   * @param provider : any
   * @returns : signInWithPopup function
   */
  AuthLogin(provider: any) {
    return this.afAuth
      .signInWithPopup(provider)
      .then((result: { user: any }) => {
        this.ngZone.run(() => {
          this.router.navigate(["dashboard"]);
        });
        this.SetUser(result.user as User);
      })
      .catch((error: { code: any }) => {
        this.translate.get(error.code).subscribe((translation) => {
          this._snackBar.open(translation, "", {
            duration: this.durationInSeconds * 1000,
            panelClass: ['error-snackbar']
          });
        });
      });
  }


  /**
   * set up user data when sign in with username/password, 
   * sign up with username/password and sign in with social auth
   * provider in firestore database using angularfirestore + 
   * angularfirestoredocument service
   * 
   * @param user : user interface
   * @returns : data in document
   */
  async SetUser(user: User) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${user.uid}`
    );
    const userI = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
      super: user.super,
      language: user.language,
      accountType: user.accountType,
      planPendent: user.planPendent,
      requestedPlan: user.requestedPlan,
      sessions: user.sessions,
      sessionsTrial: user.sessionsTrial,
      serveisDisponibles: user.serveisDisponibles,
    };
    return userRef.set(userI, {
      merge: true,
    });
  }

  /**
   * set up user data when sign in with username/password, 
   * sign up with username/password and sign in with social auth
   * provider in firestore database using angularfirestore + 
   * angularfirestoredocument service
   * 
   * @param user : user interface
   * @returns : data in document
   */
   async SetUser2(user: User) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${user.uid}`
    );
    
    /*
     * Al logar-se, aqui es fan les comprobacions per a que el usuari
    estigui actualitzat. En cas que faltin paràmetres bàsics es carreguen
    Amb el seu valor per defecte 
     */
    this.afs.collection("users").doc(user.uid).ref
    .get().then((doc) => {
      let userI: any = {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        photoURL: user.photoURL,
        emailVerified: user.emailVerified,
      };
      if (doc.exists) {
        let userData: User = doc.data()
        if(!userData.serveisDisponibles) userI.serveisDisponibles = [];

      }
      return userRef.set(userI, {
        merge: true,
      });
    });
    /*const userI = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
    };
    return userRef.set(userI, {
      merge: true,
    });*/
  }

  /**
   * 
   * @param user 
   */
  updateUser(user: User): void {
    const currentUser = firebase.auth().currentUser;
    currentUser.updateProfile(user).then(() => {
      console.log('update done')
      this.emit();
      this.translate.get("info-updated").subscribe((translation) => {
        this._snackBar.open(translation, "", {
          duration: this.durationInSeconds * 1000,
        });
      });
      this.SetUser2(currentUser)
    }).catch((error) => {
      console.log('update not done')
      // An error occurred
      // ...
    });
  }

  deleteUser(userId: string): void {
    this.afs.collection("customers", (ref) => ref.where("uid", "==", userId))
    .valueChanges({ idField: "cid" }).pipe(take(1)).forEach(snapshot => {
      snapshot.forEach(customer => {
        this.afs.collection("sessions", (ref) => ref.where("cid", "==", customer.cid))
        .valueChanges({ idField: "sid" }).pipe(take(1)).forEach(snap => {
          snap.forEach(session => {
            this.afs.collection("sessions").doc(session.sid).delete();
          })
        }).then((something)=> {
          this.afs.collection("customers").doc(customer.cid).delete();
        });
      })
    }).then(() => {
      const callable = this.functions.httpsCallable("removePartner");
      const obs = callable(userId);
      obs.subscribe(async (result) => {
        console.log(result);
      });
    })
    /*
    const currentUser = firebase.auth().currentUser;
    currentUser.delete().then(() => {
      this._snackBar.open('Partner eliminat', "", {
        duration: this.durationInSeconds * 1000,
      });
    })*/
  }

  /**
   * sign out function
   * 
   * @returns delete data from localStorage
   */
  SignOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem("user");
      localStorage.removeItem("userData");
      this.user = null;
      this.userData = null;
      this.router.navigate(["sign-in"]);
    });
  }
}
