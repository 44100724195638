import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { User } from "src/app/interfaces/user";
import { DataService } from "src/app/services/data.service";

@Component({
  selector: "dynamic-comp",
  template: `
    <div>
      <h4>{{ "language_change_title" | translate }}</h4>
      <hr />
      <p class="text-left">
        {{ "language_change_subtitle" | translate }}
      </p>
      <mat-form-field appearance="fill" class="material_inputs">
        <mat-label>{{ "language_change_label" | translate }}</mat-label>
        <mat-select [(value)]="selected">
          <mat-option value="es">Español</mat-option>
          <mat-option value="ca">Català</mat-option>
          <mat-option value="en">English</mat-option>
          <!-- <mat-option value="eu">Euskara</mat-option> -->
        </mat-select>
      </mat-form-field>
      <mat-dialog-actions align="end">
        <button mat-button class="rounded-cards" mat-dialog-close>
          {{ "cancel" | translate }}
        </button>
        <button
          mat-flat-button
          id="send-button"
          class="rounded-cards"
          [mat-dialog-close]="true"
          color="primary"
          (click)="updateLanguage(this.selected)"
        >
          {{ "accept" | translate }}
        </button>
      </mat-dialog-actions>
    </div>
  `,
  styleUrls: ["../account-page.component.css"],
})
export class LanguageComponent implements OnInit {
  selected: string;

  constructor(
    public translate: TranslateService,
    public dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public userInfo
    ) {}

  enter(e) {
    if (e.key == "Enter") {
      let focus = document.activeElement;
      if (
        !document.activeElement ||
        (focus.id != "nameInput" && focus.id != "send-button")
      ) {
        document.getElementById("nameInput").focus();
      } else if (focus.id == "nameInput") {
        document.getElementById("send-button").focus();
      } else if (focus.id == "send-button") {
        document.getElementById("nameInput").focus();
      }
    }
  }

  ngOnInit(): void {
    document.addEventListener("keypress", this.enter);
    this.selected = this.userInfo.language;
  }

  ngOnDestroy(): void {
    document.removeEventListener("keypress", this.enter);
  }

  updateLanguage(language) {
    let user: User = {
      uid: this.userInfo.uid,
      language: language,
    };
    this.dataService.updateUser(user);
  }
}
