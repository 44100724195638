import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "src/app/auth/auth.service";

@Component({
  selector: "dynamic-comp",
  template: `
  <div>
    <h4>{{ "user-name1" | translate }}</h4>
    <hr />
    <p class="text-left">
      {{ "user-name2" | translate }}
    </p>
    <mat-form-field appearance="fill" class="material_inputs">
      <mat-label class="form-label">{{ "username" | translate }}</mat-label>
      <input matInput #username [formControl]="userValidator" id="nameInput"/>
      <mat-error *ngIf="userValidator.invalid">
        {{ getErrorMessagePass() }}
      </mat-error>
    </mat-form-field>
    <mat-dialog-actions align="end">
      <button mat-button class="rounded-cards" mat-dialog-close>
        {{ "cancel" | translate }}
      </button>
      <button
        mat-flat-button
        id="send-button"
        class="rounded-cards"
        [mat-dialog-close]="true"
        [disabled]="userValidator.invalid"
        color="primary"
        (click)="updateName(username.value)"
      >
        {{ "accept" | translate }}
      </button>
    </mat-dialog-actions>
  </div>
  `,
  styleUrls: ["../account-page.component.css"],
})
export class NameComponent implements OnInit {
  
  userValidator = new FormControl(this.userInfo.displayName, [Validators.required]);

  constructor(
    public translate: TranslateService,
    public authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public userInfo
  ) {}

  enter(e) {
    if (e.key == "Enter") {
      let focus = document.activeElement;
      if (
        !document.activeElement ||
        (focus.id != "nameInput" && focus.id != "send-button")
      ) {
        document.getElementById("nameInput").focus();
      } else if (focus.id == "nameInput") {
        document.getElementById("send-button").focus();
      } else if (focus.id == "send-button") {
        document.getElementById("nameInput").focus();
      }
    }
  }

  ngOnInit(): void {
    document.addEventListener("keypress", this.enter);
  }

  ngOnDestroy(): void {
    document.removeEventListener("keypress", this.enter);
  }

  updateName(username) {
    this.authService.updateUser({'displayName': username});
  }

  getErrorMessagePass() {
    let trans1 = "";
    if (this.userValidator.hasError("required")) {
      this.translate
        .get("obligatorio-entrar-valor") //ENTER VALUE REQUIRED
        .subscribe((translation) => {
          trans1 = translation;
        });
      return trans1;
    }
  }
}