import { Session } from "./../../interfaces/session";
import { DataService } from "./../../services/data.service";
import { Component, OnInit } from "@angular/core";
import { Customer } from "src/app/interfaces/customer";
import { Observable } from "rxjs";
import { User } from "src/app/interfaces/user";
import { MatRadioChange } from "@angular/material/radio";
import { OnDestroy } from "@angular/core";
import { AuthService } from "src/app/auth/auth.service";
import platformServices from "src/assets/standardData/platformServices.json";
import { TranslateService } from "@ngx-translate/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialog } from "@angular/material/dialog";
import { DeletePartnerDialog } from "./dialogs/delete-partner.dialog";
import { DeleteClientDialog } from "./dialogs/delete-client.dialog";
import { DeleteSessionDialog } from "./dialogs/delete-session.dialog";

@Component({
  selector: "app-admin-page",
  templateUrl: "./admin-page.component.html",
  styleUrls: ["./admin-page.component.css"],
})
export class AdminPageComponent implements OnInit, OnDestroy {
  users: Observable<User[]>;
  customers: Observable<Customer[]>;
  sessions: Observable<Session[]>;
  userDataInfo;
  userInfo;
  serveis = platformServices;

  constructor(
    private dataService: DataService,
    private authService: AuthService,
    public translate: TranslateService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    document.body.style.backgroundColor = "#333333";
    this.users = this.dataService.getAllUsers();
    this.customers = this.dataService.getAllCustomers();
    this.sessions = this.dataService.getAllSessions();
    this.authService.getUserDataInfo().subscribe((userDataInfo) => {
      this.userDataInfo = userDataInfo;
    });
    this.authService.getUserInfo().subscribe((userInfo) => {
      this.userInfo = userInfo;
    });
    this.authService.emit();
  }

  changeUserServices($event: MatCheckboxChange, serveiId, userId, serveisD?) {
    let sdisponibles = [];
    if (serveisD) sdisponibles = serveisD;
    if (sdisponibles.includes(serveiId) && !$event.checked) {
      sdisponibles.forEach((element, index) => {
        if (element == serveiId) sdisponibles.splice(index, 1);
      });
    } else {
      sdisponibles.push(serveiId);
    }
    let user: User = {
      uid: userId,
      serveisDisponibles: sdisponibles,
    };
    this.dataService.updateUser(user);
  }

  changeUserType(event: MatRadioChange, userId: string) {
    let user: User = {
      uid: userId,
      accountType: +event.value,
      requestedPlan: null,
      planPendent: false,
    };
    this.dataService.updateUser(user);
  }

  changeSessionType(event: MatRadioChange, sessionId: string) {
    let session: Session = {
      sid: sessionId,
      tipusSessio: +event.value,
    };
    this.dataService.updateSession(session);
  }

  changeSessionValid(event: MatRadioChange, session: Session) {
    let value = event.value == "2" ? true : false;
    let session2: Session = {
      sid: session.sid,
      valida: value,
    };
    this.dataService.updateSession(session2);
  }

  openDeletePartnerDialog(user: User) {
    const dialogRef = this.dialog.open(DeletePartnerDialog, {
      data: user,
      minHeight: "200px",
      maxWidth: "600px",
      panelClass: "rounded-cards",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }

  openDeleteClientDialog(customer: Customer, session: Session) {
    const dialogRef = this.dialog.open(DeleteClientDialog, {
      data: {
        customer: customer,
        uid: this.userDataInfo.uid,
        session: session
      },
      minHeight: "200px",
      maxWidth: "600px",
      panelClass: "rounded-cards",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }

  openDeleteSessionDialog(session: Session) {
    const dialogRef = this.dialog.open(DeleteSessionDialog, {
      data: {
        session: session,
        uid: this.userDataInfo.uid
      },
      minHeight: "200px",
      maxWidth: "600px",
      panelClass: "rounded-cards",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }

  ngOnDestroy() {
    document.body.style.backgroundColor = "";
  }
}
