import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from 'src/app/interfaces/user';

@Component({
  selector: 'app-plan-pendent',
  templateUrl: './plan-pendent.component.html',
  styleUrls: ['./plan-pendent.component.css']
})
export class PlanPendentComponent implements OnInit {

  userInfo;
  userDataInfo: User;

  constructor(
    public authService: AuthService, 
    private route: Router,

    ) { }

  ngOnInit(): void {
    this.authService.getUserInfo().subscribe((userInfo) => {
      this.userInfo = userInfo;
    });
    this.authService.getUserDataInfo().subscribe((userDataInfo) => {
      this.userDataInfo = userDataInfo;
      if (this.authService.havePlan) {
        this.route.navigate(['dashboard']);
      }
    });
    this.authService.emit();
  }

  goSelectPlan() {
    this.route.navigate(['select-plan']);
  }

}
