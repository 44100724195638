import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { Session } from "src/app/interfaces/session";
import { DataService } from "src/app/services/data.service";

@Component({
  selector: "discover-time",
  template: `
    <div>
      <h4>{{ "discover_time_title" | translate }}</h4>
      <hr />
      <p class="text-left">{{ "discover_time1" | translate }}</p>
      <mat-form-field appearance="fill">
        <mat-label>{{ "discover-time" | translate }}</mat-label>
        <input
          matInput
          #discoverTime
          [formControl]="sessionDiscoverTimeValidator"
          type="number"
          placeholder="{{ 'discover_time_placeholder' | translate }}: 50 {{ 'minutes' | translate }}"
          min="50"
          id="discoverTime"
        />
        <span matSuffix>{{ "minutes" | translate }}</span>
      </mat-form-field>
      <mat-dialog-actions align="end">
        <button mat-button class="rounded-cards" mat-dialog-close>
          {{ "cancel" | translate }}
        </button>
        <button
          mat-flat-button
          id="send-button"
          class="rounded-cards"
          [mat-dialog-close]="true"
          [disabled]="sessionDiscoverTimeValidator.invalid"
          color="primary"
          (click)="updateSessionDiscoverTime(discoverTime.value)"
        >
          {{ "accept" | translate }}
        </button>
      </mat-dialog-actions>
    </div>
  `,
  styleUrls: ["./dialog.css"],
})
export class discoverTimeDialog implements OnInit {
  sessionDiscoverTimeValidator = new FormControl(this.session.discoverTime / 60, [Validators.required]);

  constructor(
    public translate: TranslateService,
    public dataService: DataService,
    @Inject(MAT_DIALOG_DATA) private session
  ) {}

  ngOnInit() {}

  updateSessionDiscoverTime(discover: number) {
    let session: Session = {
      sid: this.session.sid,
      discoverTime: discover * 60,
      counterState: discover * 60,
    };
    this.dataService.updateSession(session);
  }

  getErrorMessagePass() {
    let trans1 = "";
    if (this.sessionDiscoverTimeValidator.hasError("required")) {
      this.translate
        .get("obligatorio-entrar-valor") //ENTER VALUE REQUIRED
        .subscribe((translation) => {
          trans1 = translation;
        });
      return trans1;
    }
  }
}
