import { Component, Inject, OnInit } from "@angular/core";
import { FormArray, FormBuilder, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { Framework } from "src/app/interfaces/framework";
import { DataService } from "src/app/services/data.service";

@Component({
  selector: "session-name",
  template: `
    <div>
      <h3>{{ "framework_cols_settings_title_dialog" | translate }}</h3>
      <hr />

      <div id="titol_horitzontal">
        <p class="text-left">
          {{ "framework_cols_settings_subtitle1_dialog" | translate }}
        </p>
        <p class="text-left">
          {{ "framework_cols_settings_subtitle2_dialog" | translate }}
        </p>
        
        <div class="row inputs_horitzontal" [formGroup]="form">
          <ng-container formArrayName="titolsColumnesValidator">
            <ng-container *ngFor="let inputColumna of getTitolsColumnesValidator.controls; let i = index" [formGroupName]="i">
              <div [formGroup]="inputColumna">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>{{ "columna" | translate }} {{ i+1 }}</mat-label>
                  <input matInput formControlName="title" placeholder="{{'framework_col_title_dialog_placeholder' | translate }}" (change)="guardaArrayColumnes(i, $event.target.value)">
                  <button mat-icon-button matSuffix (click)="deleteColumna(i)" [disabled]="nColumnes <= minCols">
                    <mat-icon>delete</mat-icon>
                  </button>
                  <mat-error>
                    {{ getErrorMessage(i) }}
                  </mat-error>
                </mat-form-field>
              </div>
            </ng-container>
          </ng-container>

        </div>
        <div class="row add_inputs_button">
          <button mat-mini-fab color="primary" matTooltip="{{ 'framework_add_col_dialog_btn_tooltip' | translate }}" matTooltipPosition="before" [disabled]="nColumnes >= maxCols" (click)="addColumna()">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>

      <mat-dialog-actions align="end">
        <button mat-button class="rounded-cards" mat-dialog-close>
          {{ "cancel" | translate }}
        </button>
        <button
          mat-flat-button
          id="send-button"
          class="rounded-cards"
          [mat-dialog-close]="true"
          color="primary"
          [disabled]="getTitolsColumnesValidator.invalid || nColumnes == 0"
          (click)="updateFrameworkColumnTitles()"
        >
          {{ "accept" | translate }}
        </button>
      </mat-dialog-actions>
    </div>
  `,
  styleUrls: ["../info-dialogs/dialog.css"],
})
export class FrameworkTitolsColumnesDialog implements OnInit {
  maxCols = 6;
  minCols = 1;
  nColumnes: number;

  arrayColumnes;

  // Crear el FormGroupArray pels validators de cada input
  form = this.formBuilder.group({
    titolsColumnesValidator: this.formBuilder.array([]),
  })

  constructor(
    public translate: TranslateService,
    public dataService: DataService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public session
  ) {}

  
  get getTitolsColumnesValidator() {
    return this.form.controls["titolsColumnesValidator"] as FormArray;
  }
  
  ngOnInit() {
    this.nColumnes = 0;

    // Crear array amb 0 posicions inicials
    this.arrayColumnes = new Array<string>(0);

    // Si ja existeix columnes afegirles al validator amb el valor que ve de Firebase
    // i posar-ho a l'array recent creat
    // sumar el numero de columnes que hi ha
    if (this.session.framework.nomColumnes) {
      this.session.framework.nomColumnes.forEach((value, index) => {
        const columnaForm = this.formBuilder.group({
          title: [value, Validators.required],
        });
        this.getTitolsColumnesValidator.push(columnaForm);
        this.arrayColumnes.push(value);
    
        this.nColumnes += 1;
      });
    }
  }

  /**
   * crea el tipus de validator
   * afegeix validator a l'array de validators
   */
  addColumna() {
    const columnaForm = this.formBuilder.group({
      title: ['', Validators.required],
    });
    this.getTitolsColumnesValidator.push(columnaForm);

    this.nColumnes += 1;
  }

  /**
   * elimina el validator de l'array de validators quan es clica al botó
   * elimina de l'array de columnes el nom de la columna de la posició corresponent
   * @param indexColumna la posició de l'input que es vol borrar
   */
  deleteColumna(indexColumna: number) {
    this.getTitolsColumnesValidator.removeAt(indexColumna);
    
    // Remove element from arrayColumnes at position indexColumna
    this.arrayColumnes.splice(indexColumna, 1);

    this.nColumnes -= 1;
  }

  /**
   * cada vegada que es deselecciona el input es guarda el titol de la columna a l'array local de `arrayColumnes`
   * @param indexColumna posició de l'input
   * @param titolColumna títol de la columna introduida a l'input
   */
  guardaArrayColumnes(indexColumna: number, titolColumna: string) {
    this.arrayColumnes[indexColumna] = titolColumna;
  }

  /**
   * si existeix framework a la sessió s'assigna tots els camps de BBDD amb els corresponents
   * si NO existeix framework a la sessió, es crea un framework nou amb totes les dades corresponents
   * es fa una crida al DataService i una actualització a Firebase de la sessió amb el framework
   */
  updateFrameworkColumnTitles() {
    if (this.session.framework) {
      this.session.framework.numeroColumnes = this.getTitolsColumnesValidator.length;
      this.session.framework.nomColumnes = this.arrayColumnes;
    } else {
      let framework: Framework = {
        numeroColumnes: this.getTitolsColumnesValidator.length,
        nomColumnes: this.arrayColumnes,
      }
      this.session.framework = framework;
    }
    this.dataService.updateSession(this.session);
  }

  getErrorMessage(index) {
    let trans1 = "";
    if (this.getTitolsColumnesValidator.controls[index].get('title').hasError('required')) {
      this.translate
        .get("obligatorio-entrar-valor") //ENTER VALUE REQUIRED
        .subscribe((translation) => {
          trans1 = translation;
        });
      return trans1;
    }
  }
}
