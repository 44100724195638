import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Session } from "src/app/interfaces/session";

@Component({
    selector: 'confirmation',
    templateUrl: './confirmation.dialog.html',
    styleUrls: ["./confirmation.dialog.scss"],
  })
  export class ConfirmationDialog {
    constructor(@Inject(MAT_DIALOG_DATA) public session: Session) {}
  }