import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { MatDialog } from "@angular/material/dialog";
import { imageUpload } from "./upload-canvas.dialog";
import { DataService } from "src/app/services/data.service";
import { Card } from "src/app/interfaces/card";
import { Router } from "@angular/router";
import { DeleteCanvasDialog } from "./delete-canvas-dialog/delete-canvas.dialog";

@Component({
  selector: "app-canvas-page",
  templateUrl: "./canvas.dialog.html",
  styleUrls: ["./canvas.dialog.scss"],
})
export class Canvas implements OnInit {
  hidden = true;
  carta: Card;

  canvasImg: string;

  constructor(
    public dataService: DataService,
    public translate: TranslateService,
    public dialog: MatDialog,
    public router: Router,
    public dialogRef: MatDialogRef<Canvas>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
    this.dataService.getCard(this.data.carta.gid, this.data.carta.card.cid, this.data.carta.sid).subscribe((carta) => {
      this.carta = carta;
      if (this.carta.canvasId) this.dataService.getCanvas(this.carta.canvasId).subscribe((canvas)=> {
        this.canvasImg = canvas.img;
      })
    });
  }

  showCard() {
    let cos = document.getElementById("cos-carta-mostra-canvas") as HTMLDivElement;
    let image = document.getElementById("img-carta-mostra-canvas") as HTMLImageElement;
    let text = document.getElementById("text-carta-mostra-canvas") as HTMLParagraphElement;
    let name = document.getElementById("card-name-canvas") as HTMLParagraphElement;
    text.textContent = this.data.carta.card.text;
    name.textContent = this.data.carta.card.name;
    cos.style.backgroundColor = this.data.carta.card.color;
    if (this.data.carta.card.color == '#06d6b0') {
      image.src = "../../../assets/images/aicomplex.svg";
      text.style.color = "white";
    } else if (this.data.carta.card.color == '#26547c') {
      image.src = "../../../assets/images/aiunderstand.svg";
      text.style.color = "white";
    } else {
      image.src = "../../../assets/images/aiintuition.svg";
      text.style.color = '#26547c';
    }
  }

  uploadCanvas() {
    const dialogRef = this.dialog.open(imageUpload, {
      data: this.data.carta,
      minWidth: "400px",
      maxWidth: "500px",
      panelClass: "rounded-cards",
    });
    this.dialogRef.close();
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log(result);
      }
    });
  }

  deleteCanvas() {
    this.dataService.deleteCanvas(this.data.carta.gid, this.data.carta.card.cid, this.data.carta.sid, this.carta.canvasURL);
  }

  deleteCanvasOnline() {
    const dialogRef = this.dialog.open(DeleteCanvasDialog, {
      data: this.data.carta.card.name,
      minWidth: "400px",
      maxWidth: "500px",
      panelClass: "rounded-cards",
    });
    this.dialogRef.close();
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.dataService.deleteCanvasOnline(this.data.session, this.carta, this.data.carta.gid);
        console.log(result);
      }
    });
  }

  goCanvasOnline(canvasId: string) {
    this.dialogRef.close();
    this.router.navigate(["/canvas/" + canvasId]);
  }

  openCanvasOnline() {
    if (this.carta.canvasId == null) {
      this.dataService.createCanvasOnline(this.data.session, this.carta, this.data.carta.gid);
      this.dialogRef.close();
    } else {
      this.goCanvasOnline(this.carta.canvasId);
    }
  }
}
