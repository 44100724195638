import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Session } from "src/app/interfaces/session";

@Component({
  selector: "confirmation",
  template: `
    <div>
    <h1 mat-dialog-title><strong>{{ "framework_delete_settings_title_dialog" | translate }}</strong></h1>
    <div mat-dialog-content class="mat-typography">
      <div class="alert alert-danger d-flex align-items-center" role="alert">
        <mat-icon class="bi flex-shrink-0 me-2" width="24" height="24">warning</mat-icon>
        <div>
          <strong>{{ "alert-delete-session" | translate }}</strong>
        </div>
      </div>
      <h3>{{ "framework_delete_settings_subtitle1_dialog" | translate }} <strong>{{ this.session.name }}</strong>. {{ "framework_delete_settings_subtitle2_dialog" | translate }}</h3>
    </div>
    <mat-dialog-actions align="end">
        <button mat-button class="rounded-cards" mat-dialog-close cdkFocusInitial>{{ "cancel" | translate }}</button>
        <button mat-flat-button class="rounded-cards" [mat-dialog-close]="true" color="warn">{{ "accept" | translate }}</button>
    </mat-dialog-actions>
    </div>
  `,
  styleUrls: ["./confirmation.dialog.scss"],
})
export class DeleteFrameworkDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public session: Session) {}
}