import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from 'src/app/interfaces/user';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-select-plan',
  templateUrl: './select-plan.component.html',
  styleUrls: ['./select-plan.component.css']
})
export class SelectPlanComponent implements OnInit {

  durationInSeconds = 4;
  userInfo;
  userDataInfo;
  tarjetes = false;
  cost_basic = (this.tarjetes)? 35 : 30; 

  constructor(
    public dialog: MatDialog,
    private translate: TranslateService,
    private _snackBar: MatSnackBar,
    private functions: AngularFireFunctions,
    private authService: AuthService,
    public router: Router,
    private dataService: DataService
  ) {
  }

  ngOnInit() {
    this.authService.getUserInfo().subscribe(user => {
      this.userInfo = user
    });
    this.authService.getUserDataInfo().subscribe((userDataInfo) => {
      this.userDataInfo = userDataInfo;
    });
    this.authService.emit();
  }

  checked($event: MatCheckboxChange){
    this.cost_basic = ($event.checked)? 35 : 30;
  }

  nextPlan(futureAccountType) {
    let data = {
      userName: this.userInfo.displayName,
      email: this.userInfo.email,
      accountType: this.userDataInfo.accountType.toString(),
      futureAccountType: futureAccountType,
      tarjetes_online: this.tarjetes
    };
    const callable = this.functions.httpsCallable("sendAccountEmail");
    const obs = callable(data);
    obs.subscribe(async (result) => {
      console.log(result);
    });

    this.translate
      .get(
        "Correu enviat. La teva solicitud serà revisada i enviarem un correu amb la confirmació"
      )
      .subscribe((translation) => {
        this._snackBar.open(translation, "", {
          duration: this.durationInSeconds * 1000,
        });
      });
    let userId = this.authService.userId;
    let user: User = {
      uid: userId,
      requestedPlan: futureAccountType,
      planPendent: true,
    }
    this.dataService.updateUser(user);
    this.router.navigate(['plan-pendent'])
  }
}
