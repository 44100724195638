import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanvasComponent } from './canvas/canvas.component';
import { DiscoverComponent } from './discover/discover.component';
import { PrioritizeComponent } from './prioritize/prioritize.component';

const routes: Routes = [
  {
    path: 'discover/:urlId',
    component: DiscoverComponent,
  },
  {
    path: 'prioritize/:urlId',
    component: PrioritizeComponent,
  },
  {
    path: 'canvas/:cardId',
    component: CanvasComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BoardRoutingModule { }
