import { Session } from "./../interfaces/session";
import { User } from "./../interfaces/user";
import { Observable } from "rxjs";
import { Customer } from "./../interfaces/customer";
import { AuthService } from "./../auth/auth.service";
import { Injectable, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { AngularFirestore } from "@angular/fire/firestore";
import firebase from "firebase/app";
import { Card } from "../interfaces/card";
import { Participant } from "../interfaces/participant";
import { MatSnackBar } from "@angular/material/snack-bar";
import { take } from "rxjs/operators";
import "firebase/storage";
import { Canvas } from "../interfaces/canvas";
import { Framework } from "../interfaces/framework";

@Injectable({
  providedIn: "root",
})
export class DataService implements OnInit {
  durationInSeconds = 4;

  constructor(
    public afs: AngularFirestore,
    public router: Router,
    public translate: TranslateService,
    public authService: AuthService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {}

  /**
   * upload the user photo to Firebase Storage
   * @param file file to upload
   */
  uploadProfilePhoto(file: File, profileUrl) {
    if (profileUrl) firebase.storage().refFromURL(profileUrl).delete();
    let userId = this.authService.userId;
    let storageRef = firebase.storage().ref();

    // Create the file metadata
    var metadata = {};

    // Upload file and metadata to the object 'images/mountains.jpg'
    var uploadTask = storageRef
      .child("/profilePhoto/" + userId + "/" + file.name)
      .put(file, metadata);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log("Upload is paused");
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;

          // ...

          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log("File available at", downloadURL);
          this.authService.updateUser({ photoURL: downloadURL });
        });
      }
    );
  }

  deleteProfilePhoto(url) {
    let userId = this.authService.userId;
    firebase.storage().refFromURL(url).delete();
    this.authService.updateUser({ photoURL: null });
  }

  /**
   * Upload the customer logo to Firebase Storage
   * @param file file to upload
   * @param cid customerId
   */

  uploadCustomerLogo(file: File, customerid: string, logoUrl) {
    if (logoUrl) firebase.storage().refFromURL(logoUrl).delete();
    let storageRef = firebase.storage().ref();

    // Create the file metadata
    var metadata = {};

    // Upload file and metadata to the object 'images/mountains.jpg'
    var uploadTask = storageRef
      .child("/customerLogo/" + customerid + "/" + file.name)
      .put(file, metadata);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log("Upload is paused");
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;

          // ...

          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log("File available at", downloadURL);
          this.afs
            .collection("customers")
            .doc(customerid)
            .update({ logoURL: downloadURL });
        });
      }
    );
  }

  //CANVAS UPLOAD
  uploadCanvas(
    file: File,
    groupid: string,
    cardid: string,
    sessionid: string,
    teCanvas: boolean,
    canvasUrl: string
  ) {
    if (teCanvas) firebase.storage().refFromURL(canvasUrl).delete();

    let storageRef = firebase.storage().ref();

    // Create the file metadata
    var metadata = {};

    // Upload file and metadata to the object 'images/mountains.jpg'
    var uploadTask = storageRef
      .child("/canvas/" + sessionid + "/" + cardid + "/" + file.name)
      .put(file, metadata);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log("Upload is paused");
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;

          // ...

          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log("File available at", downloadURL);
          this.afs
            .collection("sessions")
            .doc(sessionid)
            .collection(groupid)
            .doc(cardid)
            .update({ canvasURL: downloadURL })
            .then(() => {
              if (!teCanvas) {
                this.afs
                  .collection("sessions")
                  .doc(sessionid)
                  .update({
                    numeroCanvas: firebase.firestore.FieldValue.increment(1),
                  });
              }
            });
        });
      }
    );
  }

  getCard(groupid: string, cardid: string, sessionid: string) {
    return this.afs
      .collection("sessions")
      .doc(sessionid)
      .collection(groupid)
      .doc(cardid)
      .valueChanges({ idField: "cid" });
  }

  deleteCanvas(
    groupid: string,
    cardid: string,
    sessionid: string,
    canvasUrl: string
  ) {
    firebase.storage().refFromURL(canvasUrl).delete();
    this.afs
      .collection("sessions")
      .doc(sessionid)
      .collection(groupid)
      .doc(cardid)
      .update({ canvasURL: null })
      .then(() => {
        this.afs
          .collection("sessions")
          .doc(sessionid)
          .update({
            numeroCanvas: firebase.firestore.FieldValue.increment(-1),
          });
      });
  }

  saveCanvasImage(img: string, canvasId: string) {
    this.afs.collection("canvas").doc(canvasId).update({img: img});
  }

  /**
   * create customer and add to database
   *
   * @param customer: customer
   */
  setNewCustomer(customer: Customer) {
    let trans = "";
    const user = JSON.parse(localStorage.getItem("user"));
    customer.uid = user.uid;
    this.afs
      .collection("customers")
      .add(customer)
      .then(() => {
        this.translate.get("customer-added").subscribe((translation) => {
          trans = translation;
        });
        this._snackBar.open(trans, "", {
          duration: this.durationInSeconds * 1000,
        });
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }

  setTrialCustomer(customer: Customer, userId: any) {
    let trans = "";
    customer.uid = userId;
    customer.trial = true;
    this.afs
      .collection("customers")
      .add(customer)
      .then((doc) => {
        let session: Session;
        session = {
          name: "Trial session",
          description:
            "Esta sesión es de prueba. Modifiquela tanto como desee para experimentar con nuestro producto. ",
          discoverTime: 50 * 60,
          counterState: 50 * 60,
          tipusSessio: 1,
          lang: "es",
          valida: true,
          numeroCartesCarregades: 0,
          numeroCartesTrobades: 0,
          numeroCartesBorrades: 0,
          estat: 0,
          serveisActivats: {
            1: true,
          },
        };
        session.cid = doc.id;
        this.afs
          .collection("sessions")
          .add(session)
          .then((docRef) => {
            this.afs
              .collection("users")
              .doc(userId)
              .update({
                sessionsTrial: firebase.firestore.FieldValue.increment(1),
              });
            this.afs
              .collection("sessions")
              .doc(docRef.id)
              .update({
                url: this.getNewUrl(docRef.id),
              });
          })
          .catch((error) => {
            console.error("Error adding document: ", error);
          });
        this.translate.get("customer-added").subscribe((translation) => {
          trans = translation;
        });
        this._snackBar.open(trans, "", {
          duration: this.durationInSeconds * 1000,
        });
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }

  /**
   *
   * @param session
   * @param customerId
   */
  setNewSession(session: Session, customerId: string) {
    let trans = "";
    session.cid = customerId;
    let userId = this.authService.userId;
    this.afs
      .collection("sessions")
      .add(session)
      .then((docRef) => {
        if (session.tipusSessio==2) this.afs
          .collection("users")
          .doc(userId)
          .update({
            disponibleSessions: firebase.firestore.FieldValue.increment(-1),
          });
        if (session.tipusSessio == 2) {
          this.afs
            .collection("users")
            .doc(userId)
            .update({
              sessions: firebase.firestore.FieldValue.increment(1),
            });
        } else {
          this.afs
            .collection("users")
            .doc(userId)
            .update({
              sessionsTrial: firebase.firestore.FieldValue.increment(1),
            });
        }
        this.afs
          .collection("sessions")
          .doc(docRef.id)
          .update({
            url: this.getNewUrl(docRef.id),
          })
          .then(() => {
            this.translate.get("session-added").subscribe((translation) => {
              trans = translation;
            });
            this._snackBar.open(trans, "", {
              duration: this.durationInSeconds * 1000,
            });
          });
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }

  modifyService(sid, serveiId: number, value: boolean) {
    var serviceUpdater = {};
    serviceUpdater[`serveisActivats.${serveiId}`] = value;
    this.afs.collection("sessions").doc(sid).update(serviceUpdater);
  }

  updateUser(user: User) {
    this.afs.collection("users").doc(user.uid).update(user);
  }

  updateSession(session: Session) {
    this.afs.collection("sessions").doc(session.sid).update(session);
  }

  updateParticipants(session: Session, participant: Participant) {
    this.afs
      .collection("sessions")
      .doc(session.sid)
      .collection("participants")
      .doc(participant.pid)
      .update(participant);
  }

  updateCustomer(customer: Customer) {
    this.afs.collection("customers").doc(customer.cid).update(customer);
  }

  /**
   * list customers from database
   * @returns: array of customers
   */
  getCustomers(): Observable<Customer[]> {
    let userId = this.authService.userId;
    return this.afs
      .collection("customers", (ref) => ref.where("uid", "==", userId))
      .valueChanges({ idField: "cid" }) as Observable<Customer[]>;
  }

  getCustomers2(userId: string) {
    return this.afs
      .collection("customers", (ref) => ref.where("uid", "==", userId))
      .valueChanges({ idField: "cid" })
      .pipe(take(1));
  }

  /**
   *
   * @param customerId
   * @returns
   */
  getCustomer(customerId) {
    let userId = this.authService.userId;
    return this.afs
      .collection("customers")
      .doc(customerId)
      .valueChanges() as Observable<Customer>;
  }

  deleteCustomer(customerId: string) {
    let trans = "";
    this.afs
      .collection("customers")
      .doc(customerId)
      .delete()
      .then(() => {
        this.translate.get("customer-deleted").subscribe((translation) => {
          trans = translation;
        });
        this._snackBar.open(trans, "", {
          duration: this.durationInSeconds * 1000,
        });
      });
  }

  deleteCustomer2(customerId: string, userId: string) {
    let trans = "";
    this.getSessions(customerId)
      .pipe(take(1))
      .forEach((snapshot) => {
        snapshot.forEach((session) => {
          this.deleteSession2(session, userId);
        });
      })
      .then(() => {
        this.afs
          .collection("customers")
          .doc(customerId)
          .delete()
          .then(() => {
            this.translate.get("customer-deleted").subscribe((translation) => {
              trans = translation;
            });
            this._snackBar.open(trans, "", {
              duration: this.durationInSeconds * 1000,
            });
          });
      });
  }

  /**
   *
   * @param customerId
   * @returns
   */
  getSessions(customerId) {
    let userId = this.authService.userId;
    return this.afs
      .collection("sessions", (ref) => ref.where("cid", "==", customerId))
      .valueChanges({ idField: "sid" }) as Observable<Session[]>;
  }

  /**
   *
   * @param sessionId
   * @returns
   */
  getSession(sessionId) {
    return this.afs
      .collection("sessions")
      .doc(sessionId)
      .valueChanges() as Observable<Session>;
  }

  /**
   *
   * @param sessionUrl
   * @returns
   */
  getSessionByUrl(sessionUrl: string) {
    return this.afs
      .collection("sessions", (ref) => ref.where("url", "==", sessionUrl))
      .valueChanges({ idField: "sid" }) as Observable<Session[]>;
  }

  setNewSessionUrl(session: Session) {
    session.url = this.getNewUrl(session.sid);
    this.afs.collection("sessions").doc(session.sid).update(session);
  }

  /**
   *
   * @param s
   * @returns
   */
  getNewUrl(s: string) {
    let random = [...Array(10)]
      .map((i) => (~~(Math.random() * 36)).toString(36))
      .join("");
    var finaly = random + s;
    var a = finaly.split(""),
      n = a.length;
    for (var i = n - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var tmp = a[i];
      a[i] = a[j];
      a[j] = tmp;
    }
    return a.join("");
  }

  moveCard(sessionId: string, previousId: string, newId: string, card: Card) {
    this.afs.firestore.runTransaction(() => {
      const promise = Promise.all([
        this.afs
          .collection("sessions")
          .doc(sessionId)
          .collection(previousId)
          .doc(card.cid)
          .delete(),
        this.afs
          .collection("sessions")
          .doc(sessionId)
          .collection(newId)
          .doc(card.cid)
          .set(card),
      ]);
      if (newId == "garbage") {
        this.afs
          .collection("users")
          .doc(this.authService.userId)
          .update({
            cartesBorrades: firebase.firestore.FieldValue.increment(1),
          });
      }
      return promise;
    });
  }
  setNewCard(sessionId: string, card: Card) {
    let sessionRef = this.afs.collection("sessions").doc(sessionId);

    sessionRef.collection("discovered").add(card);
    sessionRef.update({
      numeroCartesTrobades: firebase.firestore.FieldValue.increment(1),
      numeroCartesCarregades: firebase.firestore.FieldValue.increment(1),
    });
  }

  deleteSession(session: Session) {
    let trans = "";
    let userId = this.authService.userId;
    this.afs
      .collection("sessions")
      .doc(session.sid)
      .delete()
      .then(() => {
        if (session.tipusSessio == 2) {
          this.afs
            .collection("users")
            .doc(userId)
            .update({
              sessions: firebase.firestore.FieldValue.increment(-1),
            });
        } else {
          this.afs
            .collection("users")
            .doc(userId)
            .update({
              sessionsTrial: firebase.firestore.FieldValue.increment(-1),
            });
        }
        this.translate.get("session-deleted").subscribe((translation) => {
          trans = translation;
        });
        this._snackBar.open(trans, "", {
          duration: this.durationInSeconds * 1000,
        });
      });
  }

  deleteSession2(session: Session, uid: string) {
    let trans = "";
    let userId = uid;
    this.afs
      .collection("sessions")
      .doc(session.sid)
      .delete()
      .then(() => {
        if (session.tipusSessio == 2) {
          this.afs
            .collection("users")
            .doc(userId)
            .update({
              sessions: firebase.firestore.FieldValue.increment(-1),
            });
        } else {
          this.afs
            .collection("users")
            .doc(userId)
            .update({
              sessionsTrial: firebase.firestore.FieldValue.increment(-1),
            });
        }
        this.translate.get("session-deleted").subscribe((translation) => {
          trans = translation;
        });
        this._snackBar.open(trans, "", {
          duration: this.durationInSeconds * 1000,
        });
      });
    console.log("sessions eliminades");
  }

  getParticipants(sessionId: string) {
    return this.afs
      .collection("sessions")
      .doc(sessionId)
      .collection("participants")
      .valueChanges({ idField: "pid" }) as Observable<Participant[]>;
  }

  setNewParticipant(participant: Participant, sessionId: string) {
    this.afs
      .collection("sessions")
      .doc(sessionId)
      .collection("participants")
      .add(participant);
  }

  deleteParticipant(pid: string, sessionId: string) {
    this.afs
      .collection("sessions")
      .doc(sessionId)
      .collection("participants")
      .doc(pid)
      .delete();
  }

  getGroupCards(sessionId: string, groupId: string) {
    return this.afs
      .collection("sessions")
      .doc(sessionId)
      .collection(groupId)
      .valueChanges({ idField: "cid" }) as Observable<Card[]>;
  }

  getChangedCard(sessionId: string, groupId: string) {
    return this.afs
      .collection("sessions")
      .doc(sessionId)
      .collection(groupId)
      .stateChanges(["added"]);
  }

  getCardsNumber(sessionId: string): number {
    return 10;
  }

  getCards() {
    return this.afs
      .collection("Cards", (ref) => ref.where("es", "!=", ""))
      .valueChanges({ idField: "cardId" }) as Observable<Card[]>;
  }

  getTrialCards() {
    let cards_ids = [
      "06041014",
      "06041021",
      "06041038",
      "06042011",
      "06042028",
      "06042035",
      "06043018",
      "06043025",
      "06043032",
    ];
    return this.afs
      .collection("Cards", (ref) =>
        ref.where(firebase.firestore.FieldPath.documentId(), "in", cards_ids)
      )
      .valueChanges({ idField: "cardId" }) as Observable<Card[]>;
  }

  getAllUsers() {
    return this.afs
      .collection("users")
      .valueChanges({ idField: "uid" }) as Observable<User[]>;
  }
  getAllCustomers() {
    return this.afs
      .collection("customers")
      .valueChanges({ idField: "cid" }) as Observable<Customer[]>;
  }
  getAllSessions() {
    return this.afs
      .collection("sessions")
      .valueChanges({ idField: "sid" }) as Observable<Session[]>;
  }

  getVerbs() {
    return this.afs.collection("verbs").valueChanges({ idField: "vid" });
  }

  actualitzarAnalitics(carta: Card, session: Session, customer?: Customer) {
    session.analitics.colors[carta.color] =
      session.analitics.colors[carta.color] - 1;
    customer.analitics.colors[carta.color] =
      customer.analitics.colors[carta.color] - 1;
    this.afs
      .collection("sessions")
      .doc(session.sid)
      .update({
        numeroCartesTrobades: firebase.firestore.FieldValue.increment(-1),
      });
    carta.verbs.forEach((verb) => {
      session.analitics.verbs[verb] -= 1;
      customer.analitics.verbs[verb] -= 1;
    });
    this.afs.collection("sessions").doc(session.sid).update({
      analitics: session.analitics,
    });
    this.afs.collection("customers").doc(customer.cid).update({
      analitics: customer.analitics,
    });
  }

  getCanvas(canvasId: string) {
    return this.afs
      .collection("canvas")
      .doc(canvasId)
      .valueChanges({ idField: "canvasId" }) as Observable<Canvas>;
  }

  deleteCanvasOnline(session: Session, carta: Card, gid: string) {
    this.afs
      .collection("canvas")
      .doc(carta.canvasId)
      .delete()
      .then(() => {
        this.afs
          .collection("sessions")
          .doc(session.sid)
          .update({
            numeroCanvas: firebase.firestore.FieldValue.increment(-1),
          })
          .then(() => {
            this.afs
              .collection("sessions")
              .doc(session.sid)
              .collection(gid)
              .doc(carta.cid)
              .update({ canvasId: null });
          })
          .catch((error) => {
            console.error("Error adding document: ", error);
          })
          .catch((error) => {
            console.error("Error adding document: ", error);
          });
      });
  }

  createCanvasOnline(session: Session, carta: Card, gid: string) {
    let canvas: Canvas = {
      cartaId: carta.cardId,
      groupId: gid,
      cardName: carta.name,
      sessionId: session.sid
    };

    this.afs
      .collection("canvas")
      .add(canvas)
      .then((docRef) => {
        this.afs
          .collection("sessions")
          .doc(session.sid)
          .collection(gid)
          .doc(carta.cid)
          .update({ canvasId: docRef.id })
          .then(() => {
            this.afs
              .collection("sessions")
              .doc(session.sid)
              .update({
                numeroCanvas: firebase.firestore.FieldValue.increment(1),
              })
              .then(() => {
                this.router.navigate(["/canvas/" + docRef.id]);
              });
          });
      });
      console.log(session)
      console.log(carta)
  }

  addPosit(posit: any, canvasId: string, arrayId: string) {
    let temp = {};
    temp[`${arrayId}`] = firebase.firestore.FieldValue.arrayUnion(posit);
    this.afs.collection("canvas").doc(canvasId).set(temp, { merge: true });
  }

  deletePosit(posit: any, canvasId: string, arrayId: string) {
    let temp = {};
    temp[`${arrayId}`] = firebase.firestore.FieldValue.arrayRemove(posit);
    this.afs.collection("canvas").doc(canvasId).set(temp, { merge: true });
  }

  updatePosit(oldPosit: any, newPosit: any, canvasId: string, arrayId: string) {
    this.deletePosit(oldPosit, canvasId, arrayId);
    this.addPosit(newPosit, canvasId, arrayId);
  }

  actualitzarFramework(sessionId: string, framework: Framework) {
    let session: Session = {
      sid: sessionId,
      framework: framework
    }
    this.updateSession(session);
  }

  restarFramework(sessionId: string, frameworkPosition, framework: Framework) {
    let frameTitol = frameworkPosition.titol;
    let updateobj = framework.quadratsClicats.find(i => i.titol === frameTitol);
    updateobj.vegades--;
    let index = framework.quadratsClicats.indexOf(updateobj);
    framework.quadratsClicats[index] = updateobj;
    
    this.updateSession({sid: sessionId, framework: framework});
  }
}
