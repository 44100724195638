import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { SharedModule } from '../shared.module';

import { DashBoardRoutingModule } from './dash-board-routing.module';

import { ClientsViewComponent } from './clients-view/clients-view.component';
import { ClientHomeComponent } from './client-home/client-home.component';
import { SessionViewComponent } from './session-view/session-view.component';
import { ConfirmationDialog } from './client-home/confirmation-dialog/confirmation.dialog';
import { ConfirmationDialog2 } from './session-view/confirmation-dialog/confirmation.dialog';
import { ConfirmationDialog3 } from './clients-view/confirmation-dialog/confirmation.dialog';
import { OrderByPipe } from './clients-view/order-by/order-by.pipe';
import { CustomerNameComponent } from './client-home/dialogs/cname.dialog';
import { LogoComponent } from './client-home/dialogs/logo.dialog';
import { SectorComponent } from './client-home/dialogs/sector.dialog';
import { FacturacioComponent } from './client-home/dialogs/facturacio.dialog';
import { sessionNameDialog } from './session-view/info-dialogs/session-name.dialog';
import { discoverTimeDialog } from './session-view/info-dialogs/discover-time.dialog';
import { sessionParticipantsDialog } from './session-view/info-dialogs/participants.dialog';
import { sessionDescriptionDialog } from './session-view/info-dialogs/session-description.dialog';
import { WorkersComponent } from './client-home/dialogs/workers.dialog';
import { AddSessionDialog } from './client-home/dialogs/add-session.dialog';
import { NoDisponibleSessionsDialog } from './client-home/dialogs/noDisponibleSessions.dialog';
import { sessionLangDialog } from './session-view/info-dialogs/session-lang.dialog';
import { FrameworkTitolsColumnesDialog } from './session-view/framework-dialogs/framework-titols-columnes.dialog';
import { FrameworkTitolsFilesDialog } from './session-view/framework-dialogs/framework-titols-files.dialog';
import { DeleteFrameworkDialog } from './session-view/confirmation-dialog/delete-framework-confirmation.dialog';


@NgModule({
  declarations: [
    ClientsViewComponent,
    ClientHomeComponent,
    SessionViewComponent,
    ConfirmationDialog,
    ConfirmationDialog2,
    ConfirmationDialog3,
    AddSessionDialog,
    NoDisponibleSessionsDialog,
    OrderByPipe,
    CustomerNameComponent,
    LogoComponent,
    SectorComponent,
    FacturacioComponent,
    WorkersComponent,
    sessionNameDialog,
    discoverTimeDialog,
    sessionDescriptionDialog,
    sessionParticipantsDialog,
    sessionLangDialog,
    FrameworkTitolsColumnesDialog,
    FrameworkTitolsFilesDialog,
    DeleteFrameworkDialog
  ],
  imports: [
    CommonModule,
    DashBoardRoutingModule,
    SharedModule,
    DragDropModule
  ]
})
export class DashBoardModule { }
