import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { Session } from "src/app/interfaces/session";
import { DataService } from "src/app/services/data.service";

@Component({
  selector: "session-name",
  template: `
    <div>
      <h4>{{ 'session_name_title' | translate }}</h4>
      <hr />
      <p class="text-left">{{ 'session_name1' | translate }}</p>
      <mat-form-field appearance="fill" class="material_inputs">
        <mat-label class="form-label">{{ 'session_name_label' | translate }}</mat-label>
        <input
          matInput
          #sessionName
          [formControl]="nameValidator"
          id="nameInput"
        />
        <mat-error *ngIf="nameValidator.invalid">
          {{ getErrorMessagePass() }}
        </mat-error>
      </mat-form-field>
      <mat-dialog-actions align="end">
        <button mat-button class="rounded-cards" mat-dialog-close>
          {{ "cancel" | translate }}
        </button>
        <button
          mat-flat-button
          id="send-button"
          class="rounded-cards"
          [mat-dialog-close]="true"
          [disabled]="nameValidator.invalid"
          color="primary"
          (click)="updateSessionName(sessionName.value)"
        >
          {{ "accept" | translate }}
        </button>
      </mat-dialog-actions>
    </div>
  `,
  styleUrls: ["./dialog.css"],
})
export class sessionNameDialog implements OnInit {
  nameValidator = new FormControl(this.session.name, [Validators.required]);

  constructor(
    public translate: TranslateService,
    public dataService: DataService,
    @Inject(MAT_DIALOG_DATA) private session
  ) {}

  ngOnInit() {}

  updateSessionName(sessionName: string) {
    let session: Session = {
      sid: this.session.sid,
      name: sessionName,
    };
    this.dataService.updateSession(session);
  }

  getErrorMessagePass() {
    let trans1 = "";
    if (this.nameValidator.hasError("required")) {
      this.translate
        .get("obligatorio-entrar-valor") //ENTER VALUE REQUIRED
        .subscribe((translation) => {
          trans1 = translation;
        });
      return trans1;
    }
  }
}
